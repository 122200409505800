<template>
    <div class="page">
		</br>
        <div class="header">
            <div @click="getBack">
                <img class="img" src="../../assets/img/arrow.png" style="width: 25px;height: 25px;" alt=""/>
            </div>
            <div class="logg" style="color: #000;font-weight: bold;">{{ $t('Bono de YOUTUBE') }}</div>
            <div>
              
            </div>
        </div>
		 <div class=" mt24 ml32 mr32" style="height: 0; opacity: 2; border: 0.01px solid #4CAF50;"></div>
        <div class="mt30">
            <div class="contnet">
                <div class="card">
                    <p style="color: #888" class="mt50"></p>
                    <p class="text">{{ $t('Publicar video bono') }} 10 USDT - 200 USDT</p>
                    <van-field class="input" v-model="title" :placeholder="$t('Ingrese el monto de la solicitud')"/>
                    <p class="text">{{ $t('Enlace de tu video') }}</p>
                    <van-field
                            class="input"
                            v-model="web_url"
                            :placeholder="$t('Ingrese el enlace del video post-publicado')"
                    />
                    <p class="text">{{ $t('Notas importantes sobre los bonos') }}: <br>
                        1. {{ $t('bonosTip1') }}10$-200$.<br>
                        2. {{ $t('bonosTip2') }}.<br>
                        3. {{ $t('bonosTip3') }}.<br>
                        4. {{ $t('bonosTip4') }}.</p>
                </div>
            </div>
        </div>
        <HBBTN @clickEvent="subchongzhi"><span>{{ $t('Aplicar para un premio') }} </span></HBBTN>
       <van-cell v-for="item in list" :key="item.id">
           <div class="learn" style="color: #000;">


               <div class="box">
                   <div>
                       <span>{{ $t('申请奖金') }}:</span>
                       <span>{{ item.title }} USDT</span>
                   </div>
               </div>
               <div class="box">
                   <span>{{ $t('视频URL') }}:</span>
                   <span style="font-size: 8px;">{{ item.web_url }}</span>
               </div>
               <div class="box">
                   <div>
                       <span>{{ $t('活动时间') }}:</span>
                       <span>{{ item.addtime }}</span>
                   </div>
               </div>
				<div class="box">
				    <div>
				        <span>{{ $t('实际奖励') }}:</span>
				        <span>{{ item.jiangli }}</span>
				    </div>
				</div>
               <div class="box">
                   <span>{{ $t('状态') }}:</span>
                   <span class="kna-status" v-if="item.status == 0"><span> {{ $t('待审核') }}</span></span>
                   <span class="kna-status" style="color: green;" v-if="item.status == 1"><span>{{ $t('成功') }}</span></span>
                   <span class="kna-status" style="color:red;"
                         v-if="item.status == 2"><span>{{ $t('失败') }}</span></span>
                   <span class="kna-status" style="color:red;" v-if="item.status == 3"><span>{{ $t('审核中') }}</span></span>
               </div>
               <hr>
           </div>
       </van-cell>
		<div class="footer" >
		  <van-tabbar v-model="active" route>
		    <van-tabbar-item
		      v-for="item in tabbar"
		      :key="item.title"
		      :to="item.url"
		    >
		      <span>{{ item.title }}</span>
		      <template #icon="props">
		        <img :src="props.active ? item.iconA : item.icon" alt="" />
		      </template>
		    </van-tabbar-item>
		  </van-tabbar>
		</div>
        <ROBOT></ROBOT>

    </div>
</template>

<script>
import ROBOT from '@/components/robot'
import HBBTN from '@/components/hb-btn'
import axios from "axios";
import QS from "qs";
import Clipboard from 'clipboard'
import QRCode from "qrcodejs2-fix";
import {NavBar, Progress, Icon, Button, Toast} from 'vant'

import {
    apichongzhi,
    getUrlKey,
    apichongzhiusdt,
    apiuploadimg,
    postimg,
    subActivity,
    getActivity
} from '@/http/api/'


export default {
    name: 'welcome',
    components: {
        ROBOT,
        HBBTN
    },
    data() {
        return {

            token: '',
            link: '',
            title: '',
            web_url: '',
            money: '',
            dataimg: '',

            img: "",
            list: []
        }
    },
    created() {
        const token = localStorage.getItem('key')
        console.log('token', token)
        if (token == null) {
            this.$router.replace('/login')
        }
        this.token = token;
        this.init();
    },


	computed: {
		  tabbar() {
		    return [
		      {
		        title: this.$i18n.t('tabbar.首页'),
		        icon: require('@/assets/tabbar/1.png'),
		        iconA: require('@/assets/tabbar/1-1.png'),
		        url: '/home'
		      },
		      {
		        title: this.$i18n.t('tabbar.市场'),
		        icon: require('@/assets/tabbar/2.png'),
		        iconA: require('@/assets/tabbar/2-2.png'),
		        url: '/market'
		      },

		      {
		        title: this.$i18n.t('tabbar.团队'),
		        icon: require('@/assets/tabbar/4.png'),
		        iconA: require('@/assets/tabbar/4-4.png'),
		        url: '/team'
		      },
		      {
		        title: this.$i18n.t('tabbar.我的'),
		        icon: require('@/assets/tabbar/5.png'),
		        iconA: require('@/assets/tabbar/5-5.png'),
		        url: '/my'
		      },
				
		    ]
		  }
		},
    methods: {
        subchongzhi() {
            if (this.web_url == '' || this.title == '') {
                Toast.fail(this.$t('不能为空'));
                return;
            }
			if ( this.title <10 || this.title >200) {
			    Toast.fail(this.$t('请输入范围内的金额'));
			    return;
			}


            Toast.loading({
                duration: 0,
                message: "",
                forbidClick: true,
            });
            subActivity({
                userid: this.token,
                title: this.title,
                web_url: this.web_url,
            }).then((res) => {
                Toast.clear();
                if (res.status == 1) {
                    setTimeout(() => {
                        Toast.success(this.$t(res.info));
                        this.$router.push("/huodong");
                    }, 1000);
                } else {
                    Toast.fail(res.info);
                    return;

                }

            })
        },
        /**
         * post方法，对应post请求
         * @param {String} url [请求的url地址]
         * @param {Object} params [请求时携带的参数]
         */
        postdata(url, params) {

            return new Promise((resolve, reject) => {
                axios
                    .post(url, QS.stringify(params))
                    .then((res) => {
                        resolve(res.data);
                    })
                    .catch((err) => {
                        reject(err.data);
                    });
            });
        },
        upLoad() {
            this.$refs.avatarInput.dispatchEvent(new MouseEvent("click"));
        },
        changeImage() {
            var files = this.$refs.avatarInput.files;
            var that = this;

            function readAndPreview(file) {
                //Make sure `file.name` matches our extensions criteria
                if (/\.(jpe?g|png|gif)$/i.test(file.name)) {
                    var reader = new FileReader();
                    reader.onload = function (e) {
                        // 防止重复上传
                        // if (that.imgDatas.indexOf(e.target.result) === -1) {
                        //   that.imgDatas.push(e.target.result);
                        // }
                    };
                    reader.readAsDataURL(file);
                }
            }

            readAndPreview(files[0])
            if (files.length === 0) {
                return;
            }

            this.setUploadFile(files[0])
        },
        setUploadFile(file) {
            Toast.loading({
                duration: 0,
                message: "",
                forbidClick: true,
            });


            var formData = new FormData()
            formData.append(file.name, file);// 添加到请求体

            postimg('Home/Home/headimg_img', formData)

                .then(data => {
                    Toast.clear();
                    //let data = JSON.parse(res.bodyText);
                    if (data.status == 1) {
                        this.img = data.info;
                        this.dataimg = data.info2;
                    } else {
                        Toast.fail(this.$t(data.info));
                    }
                    console.log(data);
                })
        },

        onCopy(text) {
            let clipboard = new Clipboard('.clipboardBtnUrl')
            clipboard.on('success', function (e) {
                Toast(text)
                e.clearSelection()
            })
        },
        confirmEvent() {
            console.log('ceshi')
        },
        getBack() {
            this.$router.back()
        },
        linkEvent() {
            console.log('链接')
        },
        afterRead(file) {
            // 此时可以自行将文件上传至服务器
            console.log(file)
        },
        init() {
            getActivity({
                userid: this.token,

            }).then((res) => {
                this.list = res.info;
            })
        }
    }
}
</script>

<style lang="scss" scoped>
.page {
  padding: 0 30px 40px 40px;

  .header {
    padding: 0 10px;
    height: 56px;
    line-height: 56px;
    display: flex;
    justify-content: space-between;

    .img {
      width: 40px;
    }

    .logg {
      font-size: 40px;
      font-family: PingFang SC-中粗体, PingFang SC;
      font-weight: normal;
      color: #ffffff;
    }
  }

  .contnet {
    // margin-top: 90px;
    // width: 100%;
    border-radius: 20px;
    background-color: #2a2c2e;

    .card {
      margin-bottom: 20px;
      padding: 10px 26px 40px;
      position: relative;

      .qrCode {
        text-align: center;

        img {
          width: 200px;
          height: 200px;
        }
      }

      .determine {
        border-radius: 15px;
        padding: 10px 0;
        text-align: center;
        font-size: 12px;
        margin-bottom: 50px;
        background-color: #0d9135;
      }

      .text {
        color: #ffffff;
      }

      .uplod {
        margin: 20px 0;
      }

      .introduce {
        color: #888;
      }
    }

    .description {
      color: #0d9135;
      line-height: 30px;
      letter-spacing: 1px;
    }
  }

  ::v-deep .van-uploader__upload {
    background-color: #2a2c2e;
    border: 1px solid #888;
    border-radius: 10px;
  }
}
</style>
