import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Vant from 'vant'
import i18n from './lang'
import 'lib-flexible/flexible.js'
import 'vant/lib/index.css'
import 'normalize.css'
import layout from '@/components/layout'
import hbBtn from '@/components/hb-btn'
import robot from '@/components/robot'
import '@/style/index.css'
import VueClipboard from 'vue-clipboard2'

 if(localStorage.getItem('lang')){
   i18n.locale = localStorage.getItem('lang')
 }

Vue.use(VueClipboard);
Vue.config.productionTip = false

Vue.use(Vant)
Vue.use(hbBtn)
Vue.use(layout)
Vue.use(robot)
new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App)
}).$mount('#app')
