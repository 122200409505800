<template>
    <div>
        <layout :isShowFooter="true">
            <template #title>
			
			<div class="logg" style="color: #000;font-weight: bold;">
		
			{{ $t('我的留言') }}
			</div>
			</template>
            <template #content>
                <div class="content">
                    <van-list
                            v-model="loading"
                            :finished="finished"
                            :finished-text="$t('暂无数据')"
                            @load="onLoad"
                    >
                        <van-cell v-for="item in list" :key="item.id" >
                            <div class="card" style="background: #000;">
                                <div class="introduce" @click="goDetail(item.id)">
                                    <img src="../../assets/img/info.png" alt=""/>
                                    <div class="textl">
                                        <div v-html="item.content"></div>
                                        <div class="time">{{ item.create_time }}</div>
                                    </div>
                                </div>
                                <i class="info" v-if="item.flag == 0">1</i>
                            </div>
                        </van-cell>
                    </van-list>
                </div>
            </template>
        </layout>
        <ROBOT></ROBOT>
    </div>
</template>

<script>

import ROBOT from '@/components/robot'
import {apihuoqumsg, apimsgread} from "@/http/api/";
import {NavBar, PullRefresh, Toast, List, Cell, Badge} from "vant";

export default {
    components: {
        ROBOT,
        [NavBar.name]: NavBar,
        [PullRefresh.name]: PullRefresh,
        [Toast.name]: Toast,
        [List.name]: List,
        [Cell.name]: Cell,
        [Badge.name]: Badge,
    },
    data() {
        return {
            list: [],
            loading: false,
            finished: false,
            token: "",
            page: 1,
        }
    },
    created() {
        var token = localStorage.getItem("key");
        console.log("token", token);
        if (!token) {
            this.$router.replace("/login");
        }
        this.token = token;
        this.onLoad()
    },
    methods: {
        goDetail(id) {
            this.$router.push('/messageDetails?id=' + id)
        },
        onLoad() {
            Toast.loading({
                duration: 0,
                message: this.$t('加载中'),
                forbidClick: true,
            });
            var that = this;
            apihuoqumsg({
                userid: this.token,
                page: this.page,

            }).then((res) => {
                Toast.clear();
                for (let i = 0; i < res.info.length; i++) {
                    that.list.push(res.info[i]);
                }
                // 数据全部加载完成
                if (res.info.length == 0) {
                    that.finished = true
                }
            });
            this.page++
            // 加载状态结束
            this.loading = false
        }
    }
}
</script>

<style lang="scss" scoped>
.addIcon {
  width: 42px;
  height: 42px;
}

.content {
  margin-top: 44px;
  //   padding: 0 10px;
  box-sizing: border-box;

  .card {
    position: relative;
    background-color: #fff;
    // // width: 100%;
    // height: 290px;
    // background-image: url('../../assets/img/01.png');
    // // background-size: 100%;
    // background-repeat: no-repeat;
    // background-color: #fff;
    background: linear-gradient(
                    to bottom,
                    rgba(156, 138, 39, 0.17),
                    rgb(0, 0, 0)
    );
    border-radius: 20px;
    padding: 38px 34px;

    .introduce {
      display: flex;
      justify-content: space-between;
    }

    img {
      margin-right: 20px;
      width: 90px;
      height: 90px;
    }

    .textl {
      color: #fff;
      font-size: 30px;
      letter-spacing: 2px;
    }

    .time {
      font-size: 24px;
      color: #0d9135;
      margin-top: 50px;
    }

    .info {
      position: absolute;
      top: 20px;
      left: 90px;
      color: #fff;
      font-size: 12px;
      width: 40px;
      height: 40px;
      text-align: center;
      line-height: 40px;
      //   padding: 2px 15px;
      border-radius: 51%;
      background-color: #fd602c;
    }
  }
}

// .nodata {
//   margin-top: 300px;
//
// }
</style>
