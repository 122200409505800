<template>
    <layout :isShowFooter="true">
        <template #title>
		<div class="logg" style="color: #000;font-weight: bold;">{{ $t('团队规则') }}</div>
		</template>
        <template #right></template>
        <template #content></br></br>
            <div class="description" v-html="description" style="border-radius: 0.26667rem; background-color: rgb(42, 44, 46); margin-left: 15px; padding: 20px; margin-right: 15px; color: #fff;"></div>
        </template>
    </layout>
</template>

<script>
import {
    apikongtou, apiky_buy, apiUser_mas2, getRemakes

} from "@/http/api";

export default {
    data() {
        return {
            description: '',
            token: "",
            info: [],
            tab1: {
                title: [
                    'Robot\ntipo',
                    'Robot precio',
                    'Ingreso diario',
                    'Ciclo de operación',
                    'Beneficio neto'
                ],
                data: [
                    {
                        i: 'NO1',
                        price: '$10',
                        startUp: '$3.66',
                        total: '3 días',
                        daily: '$1'
                    },
                    {
                        i: 'NO2',
                        price: '$50',
                        startUp: '$8.67',
                        total: '7 días',
                        daily: '$11.7'
                    },
                    {
                        i: 'NO3',
                        price: '$100',
                        startUp: '$10.0',
                        total: '15 días',
                        daily: '$50'
                    },
                    {
                        i: 'NO4',
                        price: '$500',
                        startUp: '$36.1',
                        total: '27 días',
                        daily: '$475'
                    },
                    {
                        i: 'NO5',
                        price: '$1000',
                        startUp: '$60.2',
                        total: '42 días',
                        daily: '$1530'
                    }
                ]
            },
            tab2: {
                title: [
                    'Puntos \nAccumula \nción',
                    'Agente \n Nivel',
                    'Subordinados\y Consumidor\en Reembolso',
                    'Descuento\n Por robo \nCompra'
                ],
                data: [
                    {
                        i: '1000',
                        price: 'LV2',
                        startUp: '20%',
                        total: '30%'
                    },
                    {
                        i: '2000',
                        price: 'LV3',
                        startUp: '25%',
                        total: '35%',
                    },
                    {
                        i: '5000',
                        price: 'LV4',
                        startUp: '30%',
                        total: '40%',
                    },
                    {
                        i: '10000',
                        price: 'LV5',
                        startUp: '35%',
                        total: '45%',
                    },
                    {
                        i: '30000',
                        price: 'LV6',
                        startUp: '40%',
                        total: '60%'
                    }
                ]
            },
            tab3: {
                title: [
                    'Agente\nNivel',
                    'Agente\n Recompensa\n Ratio(%)',
                    'Subordinado\n Diario \nBeneficio(USD)',
                    'Agente\n Recompensa\n (USD)'
                ],
                data: [
                    {
                        i: 'Downline1',
                        price: '8%',
                        startUp: '$100',
                        total: '$8'
                    },
                    {
                        i: 'Downline2',
                        price: '3%',
                        startUp: '$100',
                        total: '$3',
                    },
                    {
                        i: 'Downline3',
                        price: '2%',
                        startUp: '$100',
                        total: '$2'
                    }
                ]
            },
            tab4: {
                title: [
                    'Agente\nNivel',
                    'Referencia0 \nbjeto',
                    'A>30% of\n B/C/D',
                    '20% of B/ \n C/D < A≤3 \n 0% of B/C/D',
                    'A≤20% of \n B/C/D'
                ],
                data: [
                    {
                        i: 'Downline1',
                        price: 'B',
                        startUp: '8%',
                        total: '5.4%',
                        totals: '4%'
                    },
                    {
                        i: 'Downline2',
                        price: 'C',
                        startUp: '3%',
                        total: '1.2%',
                        totals: '0.5%'
                    },
                    {
                        i: 'Downline3',
                        price: 'D',
                        startUp: '2%',
                        total: '0.8%',
                        totals: '0.3%'
                    }
                ]
            }
        }
    },
    created() {

        getRemakes({title: '规则'}).then(res => {
            this.description = res.info.content;
        })

        var token = localStorage.getItem("key");
        console.log("token", token);
        if (!token) {
            this.$router.replace("/login");
        }
        this.token = token;
        this.init();
        apiUser_mas2({
            userid: token
        }).then((res) => {
            // 未登录踢下线
            if (res.status == 10) {
                this.$router.replace('/login')
            }
            // this.showdown = res.user.showdown
            this.info = res.user

        })
    },
    methods: {
        init() {

            this.yaoqinglist = [];
            apikongtou({
                userid: this.token,
            }).then((res) => {
                this.yaoqinglist = res.info;
            });
        },
    }
}
</script>

<style lang="scss" scoped>
.content {
  .description{
    color: #333333;
    font-size: 28px;
  }
  .card {
    background: linear-gradient(180deg, #2a2c2e 0%, #202020 100%);
    border-radius: 30px 30px 30px 30px;
    opacity: 1;
    padding: 35px;

    .typeicon {
      width: 100%;
    }

    .table {
      border-radius: 13px 13px 13px 13px;
      overflow: hidden;

      .title {
        background: linear-gradient(
                        180deg,
                        #0d9135 0%,
                        rgba(243, 204, 46, 0) 100%
        );

        .title-item {
          flex: 1;
          min-height: 90px;
          display: flex;
          justify-content: center;
          align-items: center;
          white-space: pre-wrap;
          text-align: center;
        }
      }

      .body-item {
        height: 77px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }

  .card-1 {
    .robot {
      width: 128px;
      height: 128px;
    }
  }
}
</style>
