<template>
  <div class="page">
	  </br>
    <div class="header">
	
      <div @click="getBack">
      <img class="img" src="../../assets/img/arrow.png" style="width: 25px;height: 25px;" alt=""/>
      </div>
      <div class="logg" style="color: #000;font-weight: bold;">{{ $t('帮助中心') }}</div>
      <div>

      </div>
    </div>
	 <div class=" mt24 ml32 mr32" style="height: 0; opacity: 2; border: 0.01px solid #4CAF50;"></div>
    <div class="contnet">
      <div class="card">
        <div class="collapseBox">
          <van-collapse
            v-model="activeNames"
            accordion
            v-for="(item, index) in list"
            :key="index"
          >
            <van-collapse-item :title="item.mas" :name="index" class="help">
              <div v-html="item.content"></div>
            </van-collapse-item>
          </van-collapse>
        </div>
      </div>
    </div>
	<div class="footer" >
	  <van-tabbar v-model="active" route>
	    <van-tabbar-item
	      v-for="item in tabbar"
	      :key="item.title"
	      :to="item.url"
	    >
	      <span>{{ item.title }}</span>
	      <template #icon="props">
	        <img :src="props.active ? item.iconA : item.icon" alt="" />
	      </template>
	    </van-tabbar-item>
	  </van-tabbar>
	</div>
    <ROBOT />
  </div>
</template>

<script>
import ROBOT from '@/components/robot'
// import HBBTN from '@/components/hb-btn'
import { apiwenzhang } from '@/http/api/'
export default {
  name: 'welcome',
  components: {
    ROBOT
  },
  data() {
    return {
      num: '',
      radio: 1,
      list: [],
      token: '',
      activeNames: 0
    }
  },
  created() {
    var that = this
    apiwenzhang({}).then((res) => {
      for (let i = 0; i < res.length; i++) {
        if (res[i].title == '帮助中心') {
          that.list.push(res[i])
        }
      }
    })
  },
  
  
  computed: {
  	  tabbar() {
  	    return [
  	      {
  	        title: this.$i18n.t('tabbar.首页'),
  	        icon: require('@/assets/tabbar/1.png'),
  	        iconA: require('@/assets/tabbar/1-1.png'),
  	        url: '/home'
  	      },
  	      {
  	        title: this.$i18n.t('tabbar.市场'),
  	        icon: require('@/assets/tabbar/2.png'),
  	        iconA: require('@/assets/tabbar/2-2.png'),
  	        url: '/market'
  	      },
  	   
  	      {
  	        title: this.$i18n.t('tabbar.团队'),
  	        icon: require('@/assets/tabbar/4.png'),
  	        iconA: require('@/assets/tabbar/4-4.png'),
  	        url: '/team'
  	      },
  	      {
  	        title: this.$i18n.t('tabbar.我的'),
  	        icon: require('@/assets/tabbar/5.png'),
  	        iconA: require('@/assets/tabbar/5-5.png'),
  	        url: '/my'
  	      },
  			// {
  			//   title: this.$i18n.t('客服'),
  			//   icon: require('@/assets/tabbar/3.png'),
  			//   iconA: require('@/assets/tabbar/3-3.png'),
  			//   url: '/my'
  			// }
  	    ]
  	  }
  	},
	
  methods: {
    // confirmEvent() {
    //   console.log('ceshi')
    // },
    getBack() {
      this.$router.back()
    },
    btnEvent() {
      console.log('dsfs')
    }
  }
}
</script>

<style lang="scss" scoped>
.page {
  padding: 0 30px 40px 40px;
  .header {
    padding: 0 10px;
    height: 56px;
    line-height: 56px;
    display: flex;
    justify-content: space-between;

    .img {
      width: 40px;
    }
    .logg {
      font-size: 40px;
      font-family: PingFang SC-中粗体, PingFang SC;
      font-weight: normal;
      color: #ffffff;
    }
  }
  .contnet {
    margin-top: 42px;
    width: 100%;
    border-radius: 20px;
    // background-color: #2a2c2e;
  }
  .card {
  }
  .footerBtn {
    margin-top: 50px;
  }
}
::v-deep .van-cell {
  //   background-color: #2a2c2e;
  background: #2a2c2e !important;
  border-radius: 20px;

  //   border-radius: 20px 20px 0 0;
}
::v-deep .van-collapse-item__content {
  background: #000000 !important;
  border-radius: 20px;
  //   border-radius: 0 0 20px 20px;
}
::v-deep .van-collapse-item {
  border-radius: 20px;
  //   height: 90px;
  background: #2a2c2e !important;
}
.collapseBox {
  margin-bottom: 20px;
}
.help {
  margin-bottom: 30px !important;
  // border: 1px solid red !important;
}
::v-deep .van-collapse-item--border {
  border: none !important;
}
</style>
