<template>
  <div>
    <layout :isShowFooter="true">
      <template #title>
	  
	         <div class="logg" style="color: #000;font-weight: bold;">{{ $t('机器人') }}</div>
			 </template>
      <template #content>
        <div class="content">
          <div class="ml32 mr32 robot hide box mt20" v-for="item in aituring" :key="item">
            <div class="flex_row_space-between mt30">
              <div class="flexbox hcenter ml32">
                <img class="robotIcon" src="@/assets/img/logo2.png" alt="" />
              </div>
              <div class="mr32" style="color: #0d9135">{{ item.name }}</div>
            </div>

            <div class="flex_row_space-between mt42">
              <div class="flexbox hcenter ml32">
                <span class="f30 ml32">{{ item.h_cbi }} USDT - {{ item.h_cbi2 }} USDT</span>
              </div>
              <div class="mr32">
                <span class="f30">{{ item.vip }} {{ $t('人') }}</span>
              </div>
            </div>

            <div class="flex_row_space-between mt30 ccolor">
              <div class="flexbox hcenter ml32">
                <span class="ml32">{{ $t('投资限额') }}</span>
              </div>
              <div class="mr32">
                <span class="mr32">{{ $t('购买需要直推') }}</span>
              </div>
            </div>
			
			<div class="flex_row_space-between mt42">
			  <div class="flexbox hcenter ml32">
			    <span class="f30 ml32">{{ item.dan_price }}%</span>
			  </div>
			  <div class="mr32">
			    <span class="f30">{{ item.zs_jyzhi}}% </span>
			  </div>
			</div>
		
			<div class="flex_row_space-between mt30 ccolor">
			  <div class="flexbox hcenter ml32">
			    <span class="ml32">{{ $t('日收益') }}</span>
			  </div>
			  <div class="mr32">
			    <span class="mr32">{{ $t('经验') }}</span>
			  </div>
			</div>
			
			
			

            <div class="flex_row_space-between mr32 ml32 mt42 input">
              <div class="flexbox hcenter">
                <span class="ml32 ccolor">{{ $t('有效期') }}:</span>
                <span class="ml10">{{ item.gq_day }}</span>
                <span class="ml10 ccolor">{{ $t('天') }}</span>

              </div>
              <div class="mr32">
                <div class="mr32 btn bcolor flexbox center" @click="qianyuebuy(item)">
                  {{ $t('去购买') }}
                  <img class="jiantou-b ml10" src="@/assets/icon/jiantou-b.png" alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
    </layout>
    <popup ref="popup">
      <div class="popuptip" style="color: #fff">
        <div class="flex_row_space-between f32">
          <div class="ml32">{{ $t('买个机器人') }}</div>
          <div class="mr32 flexbox" @click="$refs.popup.hide()">X</div>
        </div>

        <div class="flex_row_space-between f28 mt50">
          <div class="ml32">{{ $t('我的钱包余额') }}:</div>
          <div class="mr32 flexbox hcenter">
            <span class="f32">{{ info.usdt }}</span>
            <span class="ccolor f18">&nbsp; USDT</span>
          </div>
        </div>

        <div class="flex_row_space-between byrobot f28 mt50 ml32 mr32">
          <div class="ml32 flexbox hcenter bcolor">
            <img class="robotIcon" src="@/assets/img/logo2.png" alt="" />
            <!-- <span class="ml20">{{ $t('机器人') }}</span> -->
          </div>
          <div class="flexbox hcenter mr32 bcolor">
            <span class="f32" style="color: #fff;">{{ name }}</span>
            <span class="f18"></span>
          </div>
        </div>
        <div class="ml32 mt50">
          <!-- <span class="f32">{{ price }}</span>
          <span> USDT</span>
          <span class="ccolor ml10 mr10"><span style="font-size: 12px;"></span></span> -->
          <!--<span class="f32">{{ (price * info.rate).toFixed(0) }}</span>-->
        </div>
        <div class="ml32 mt20">
          <span>{{ $t('预计日收益') }} ：</span>
          <span>{{ earnings }} USDT</span>
        </div>
        <div class="ml32 mt20">
          <span>{{ $t('投资限额') }} ：</span>
          <span>{{ h_cbi }}USDT - {{ h_cbi2 }}USDT</span>
        </div>

        <div class="ml32 mt20">
          <span>{{ $t('购买需要直推') }} ：</span>
          <span>{{ vip }} {{ $t('人') }}</span>
        </div>
        <div class="ml32 mt20">
          <span>{{ $t('有效期') }} ：</span>
          <span>{{ gq_day }} {{ $t('天') }}</span>
        </div>
        <div class="ml32 mr32 buy mt30 flex_row_space-between">
          <input class="flex1 f32 ml20" type="number" style="color: #0d9135" :placeholder="$t('请输入投入金额')" v-model="value"
            maxlength="10" @input="changeInput()" />
          <span class="ccolor mr20">USDT</span>
        </div>
        <div class="line ml32 mr32 mt50"></div>
        <div class="ml32 ccolor">
          <span v-html="aitext"></span>

        </div>
        <div class="mt50" @click="payai">
          <hbbtn class="">{{ $t('购买') }}</hbbtn>
        </div>
      </div>
    </popup>
  </div>
</template>

<script>
import popup from '@/components/popup'
import hbbtn from '@/components/hb-btn'
import { Toast } from 'vant'
import {
  apiHangqing,
  apiRobots,
  apiUser_mas2,
  apibuyrobots,
  apimyrobots,
  apiwenzhang,
  getMoble,
  apimyshouyi,
  apizsrobots,
  apifeerobot
} from '@/http/api/'
export default {
  components: {
    popup,
    hbbtn
  },
  data () {
    return {
      aituring: [],
      token: '',
      info: [],
      value: '',
      price2: '',
      price: '',
      ids: '',
      gq_day: '',
	  zs_jyzhi: '',
      earnings: 0,
      name: '',
      dan_price: '',
      vip: '',
      istest: false,
      show: false,
      type: 0,
      info: [],
      aitext: ""
    }
  },

  created () {
    var token = localStorage.getItem('key')
    if (!token) {
      this.$router.replace('/login')
    }
    this.token = token
    Toast.loading({
      duration: 0,
      message: this.$t('加载中'),
      forbidClick: true
    })

    apiRobots({}).then((res) => {
      Toast.clear()
      this.aituring = res.info
      this.info = res.info.find(item => item.id == this.$route.query.id)
      // 快捷购买
      if (this.type == 1) {
        this.qianyuebuy(this.info);
        this.istest = false;
      }
      // 如果type 100  就是0号机器人信息
      if (this.type == 100) {
        this.istest = true;
        this.qianyuebuy(this.info);
      }
    })
    apiUser_mas2({
      userid: token
    }).then((res) => {
      // 未登录踢下线
      if (res.status == 10) {
        this.$router.replace('/login')
      }
      this.info = res.user
    })

    this.type = this.$route.query.type;

    // this.info = this.$route.query.info

    var that = this
    apiwenzhang({}).then((res) => {
      for (let i = 0; i < res.length; i++) {
        if (res[i].title == '机器人说明') {
          that.aitext = res[i].content;
        }
      }
    })
  },
  methods: {
    changeInput () {
		
      this.earnings = (this.value * (this.dan_price / 100)).toFixed(2)
   //    this.price2 = this.value * this.price
    },
    // 签约购买
    qianyuebuy (info) {
      this.$refs.popup.show()
	   this.h_cbi = info.h_cbi
	   this.h_cbi2 = info.h_cbi2
      this.price = info.price
      // this.earnings = info.earnings
	  
      this.dan_price = info.dan_price
      this.vip = info.vip
      this.gq_day = info.gq_day
      this.price2 = info.price
      this.ids = info.id
	  this.jyzhi = info.jyzhi
      this.name = info.name
    },
    payai () {
      if (this.value == '') {
        Toast.fail({
          message: this.$t('数量不能为空'),
          className: 'toastIndex'
        })
        return
      }
      console.log(this.value > parseInt(this.info.h_cbi2));
      if (this.value > parseInt(this.info.h_cbi2)) {
        Toast.fail({
          message: this.$t('请输入范围内的金额'),
          className: 'toastIndex'
        })
        return
      }
      if (this.value < parseInt(this.info.h_cbi)) {
        Toast.fail({
          message: this.$t('请输入范围内的金额'),
          className: 'toastIndex'
        })
        return
      }
      if (this.istest) {
        Toast.loading({
          duration: 0,
          message: this.$t('加载中'),
          forbidClick: true
        })
        apifeerobot({
          userid: this.token
        }).then((res) => {
          Toast.clear()
          if (res.status == 0) {
            Toast.fail({
              message: this.$t(res.info),
              className: 'toastIndex'
            })

          } else {

            Toast.success({
              message: this.$t(res.info),
              className: 'toastIndex'
            })
            setTimeout(() => {
              this.$router.push('/myRobot')
              this.show = false
            }, 1000)


          }
        })
      } else {
        Toast.loading({
          duration: 0,
          message: this.$t('加载中'),
          forbidClick: true
        })
        apibuyrobots({
          userid: this.token,
          kjid: this.ids,
          num: this.value
        }).then((res) => {
          Toast.clear()
          if (res.status == 0) {
            Toast.fail({
              message: this.$t(res.info),
              className: 'toastIndex'
            })
          } else {
            Toast.success({
              message: this.$t(res.info),
              className: 'toastIndex'
            })
            this.$router.push('/myRobot');
            this.show = false;
          }
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.content {
  .robot {
    height: 100%;
    background: #000;
    border-radius: 28px;

    .input {
      height: 89px;
      background: #2B2B2B;
      border-radius: 45px;
    }

    .btn {
      width: 165px;
      height: 56px;
      background: #44b361;
      box-shadow: 0px 8px 30px 1px rgba(243, 204, 46, 0.30000001192092896);
      border-radius: 28px 28px 28px 28px;
    }
  }

  .popuptip {
    padding: 40px 0;

    background: #2a2c2e;
    border-radius: 50px 50px 0px 0px;

    .byrobot {
      height: 130px;
      background: #00000024;
      border-radius: 27px 27px 27px 27px;
    }

    .buy {
      height: 107px;
      background: #1b1c1d;
      border-radius: 19px 19px 19px 19px;
      opacity: 1;
      border: 1px solid rgba(243, 204, 46, 0.20000000298023224);
    }
  }
}
</style>
