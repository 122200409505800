<template>
  <div class="page">
	  </br>
    <div class="header">
      <div @click="getBack" style="padding-top: 6px;">
      <img class="img" src="../../assets/img/arrow.png" style="width: 25px;height: 25px;" alt=""/>
      </div>
      <div class="logg"  style="color: #000;">{{ $t('我的团队成员') }}</div>
      <div></div>
    </div>
		 <div class=" mt24 ml32 mr32" style="height: 0; opacity: 2; border: 0.01px solid #4CAF50;"></div>
    <div class="choose">
      <van-tabs v-model="active">
        <van-tab :title="$t('Level1')" name="1">
          <van-list v-model="loading" :finished="finished" :finished-text="$t('暂无数据')"  @load="onLoad" style="background: #000;border-radius:0px 0px 5px 5px;" >
            <van-cell v-for="item in list" :key="item.id" style="border-radius:5px 5px 5px 5px;">
              <div class="learn">
                <div class="box">
                  <div style="font-size: 12px;">{{$t('账户')}}:	 {{ item.mobile || item.username }} </div>
				   <div style="font-size: 12px;">{{$t('时间')}}:{{item.addtime}}</div>
                </div>
              </div>
            </van-cell>
			</br></br></br></br>
          </van-list>
        </van-tab>
		
		
		<!-- 二级 -->
        <van-tab :title="$t('Level2')" name="2">
		<van-list v-model="loading" :finished="finished" :finished-text="$t('暂无数据')"  @load="onLoad"	style="background: #000;border-radius:0px 0px 5px 5px;">
		  <van-cell v-for="item in list" :key="item.id" style="border-radius:5px 5px 5px 5px;">
		    <div class="learn">
		      <div class="box">
		        <div>{{$t('账户')}}: {{ item.mobile || item.username }}</div>
				<div>{{$t('时间')}}:{{item.addtime}}</div>
		      </div>
		    </div>
		  </van-cell>
		  </br></br></br></br>
		</van-list>
        </van-tab>
		
		<!-- 三级 -->
		<van-tab :title="$t('Level3')" name="3">
		     <van-list v-model="loading"   :finished="finished" :finished-text="$t('暂无数据')"  @load="onLoad" style="background: #000;border-radius:0px 0px 5px 5px;">
		       <van-cell v-for="item in list" :key="item.id">
		         <div class="learn">
		           <div class="box">
		             <div>{{$t('账户')}}:{{ item.mobile || item.username }}</div>
		     		 <div>{{$t('时间')}}:{{item.addtime}}</div>
		           </div>
		         </div>
		       </van-cell>
			   </br></br></br></br>
		     </van-list>
		</van-tab>
      </van-tabs>
    </div>
	<div class="footer" >
	  <van-tabbar v-model="active" route>
	    <van-tabbar-item
	      v-for="item in tabbar"
	      :key="item.title"
	      :to="item.url"
	    >
	      <span>{{ item.title }}</span>
	      <template #icon="props">
	        <img :src="props.active ? item.iconA : item.icon" alt="" />
	      </template>
	    </van-tabbar-item>
	  </van-tabbar>
	</div>
    <ROBOT></ROBOT>
  </div>
</template>

<script>
import ROBOT from '@/components/robot'
import { NavBar, Tab, Tabs, Empty, PullRefresh, Toast, List } from "vant";

import { apigetagent, apiuserlist } from "@/http/api/";
export default {
  name: 'welcome',
  components: {
    ROBOT
  },
  data() {
    return {
      active: '1',
      list: [],
      loading: false,
      finished: false,
      page: 1,
      token: ''
    }
  },
  watch: {
    active() {
      this.resetData();
      this.onLoad();
    }
  },
  created() {
    var token = localStorage.getItem("key");
    console.log("token", token);
    if (!token) {
      this.$router.replace("/login");
    }
    this.token = token;
  },
  computed: {
    tabbar() {
      return [
        {
          title: this.$i18n.t('tabbar.首页'),
          icon: require('@/assets/tabbar/1.png'),
          iconA: require('@/assets/tabbar/1-1.png'),
          url: '/home'
        },
        {
          title: this.$i18n.t('tabbar.市场'),
          icon: require('@/assets/tabbar/2.png'),
          iconA: require('@/assets/tabbar/2-2.png'),
          url: '/market'
        },
     
        {
          title: this.$i18n.t('tabbar.团队'),
          icon: require('@/assets/tabbar/4.png'),
          iconA: require('@/assets/tabbar/4-4.png'),
          url: '/team'
        },
        {
          title: this.$i18n.t('tabbar.我的'),
          icon: require('@/assets/tabbar/5.png'),
          iconA: require('@/assets/tabbar/5-5.png'),
          url: '/my'
        },
  		// {
  		//   title: this.$i18n.t('客服'),
  		//   icon: require('@/assets/tabbar/3.png'),
  		//   iconA: require('@/assets/tabbar/3-3.png'),
  		//   url: '/my'
  		// }
      ]
    }
  },
  methods: {
    copyEvent() {
      console.log('fuzhi ');
    },
    getBack() {
      this.$router.back();
    },
    resetData() {
      // 重置数据加载状态
      this.list = [];
      this.page = 1;
      this.loading = false;
      this.finished = false;
    },
    onLoad() {
      // 开始加载数据
      this.loading = false;
      Toast.loading({
        duration: 0,
        // message: this.$t('加载中'),
        forbidClick: true,
      });

      apiuserlist({
        userid: this.token,
        page: this.page,
        type: this.active
      }).then((res) => {
        Toast.clear();
        // 清空当前列表并添加新数据
        this.list = res.info;
        // 检查是否所有数据已加载完成
        this.finished = res.info.length === 0;
      }).catch((error) => {
        // 错误处理
        Toast.clear();
        console.error("Error fetching user list:", error);
      });

      // 数据加载完成后，增加页码以便下一次加载
      this.page++;
    }
  }
}
</script>

<style lang="scss" scoped>
.page {
  //   padding: 0 30px;
  .header {
    padding: 0 40px;
    height: 56px;
    line-height: 56px;
    display: flex;
    justify-content: space-between;
    .close {
      width: 28px;
      height: 28px;
    }
    .img {
      width: 40px;
    }
    .logg {
      font-size: 40px;
      font-family: PingFang SC-中粗体, PingFang SC;
      font-weight: normal;
      color: #ffffff;
    }
  }
  .choose {
    margin-top: 20px;
    padding: 0 24px;
    .learn {
      margin-top: 30px;
      color: #fff;
      padding: 20px 25px;
      border-bottom: 1px solid #2a2c2e;
      .box {
        color: #888;
        // margin-bottom: 10px;
        align-items: center;
        display: flex;
        justify-content: space-between;
        .natixis {
          margin-bottom: 20px;
        }
      }
    }
  }
  .pitera {
    color: #fff;
    line-height: 60px;
    font-size: 24px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
  }
  .footer {
    position: fixed;
    font-size: 24px;
    color: #888;
    bottom: 0;
    height: 90px;
    width: 100%;
    text-align: center;
    line-height: 90px;
    // background-color: #2a2c2e;
    border-radius: 30px 30px 0 0;
    .num {
      color: #0d9135;
      font-size: 30px;
      margin-right: 30px;
    }
    .comeTo {
      margin-right: 20px;
    }
    .addUpTo {
      font-size: 30px;
      color: #fff;
    }
  }
}
</style>
