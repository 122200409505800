<template>
  <div class="page">
	  </br>
    <div class="header">
      <div @click="getBack">
      <img class="img" src="../../assets/img/arrow.png" style="width: 25px;height: 25px;" alt=""/>
      </div>
      <div class="logg" style="color: #000;font-weight: bold;">{{ $t('消息详情') }}</div>
      <div> </div>
    </div>
	<div class=" mt24 ml32 mr32" style="height: 0; opacity: 2; border: 0.01px solid #4CAF50;"></div>
    <div class="contnet">
      <div class="card" v-if="info.type == 0">
      <img src="../../assets/img/logo.png" alt="" />
        <div class="title">
		  <div v-html="info.content"></div>
        </div>
       
        <div class="description">
          <div>{{ info.create_time }}</div>
        </div>
      </div>
	   <div style="padding: 10px 26px 40px;" v-if="info.type == 1">
			
				<div class="natixis" style="font-size: 14px; color: #FFFFFF;">
				  <div class="spec" style="text-align: center; height: 20px;font-size: 16px;">{{$t('赠送')}}</div>
				  <div class="spec" style="line-height: 30px;">{{$t('出售/赠送人')}}：{{info.username}}</div>
				  <div class="spec" style="line-height: 30px;">Robot：{{info.name}}</div>
				  <div class="spec" style="line-height: 30px;">{{ $t('价格') }} : {{info.price}}</div>
				  <div class="spec" style="line-height: 30px;">{{$t("激活金额") }}：{{info.dan_price}}</div>
				  <div class="spec" style="line-height: 30px;">{{ $t('有效期') }} : {{info.gq_day}}{{ $t('天') }}</div>
				  <div class="spec" style="line-height: 30px;">{{ $t('唯一码') }} : {{info.code}}</div>
				  <div class="" v-if="info.status ==0 && info.type == 1">
					  
				  <span style="color:#cb9d27;min-width: 80px; height: 30px;line-height:30px;text-align: center;border-radius: 50px;background-color: #0055AF;width: 90px;display: inline-block;" @click="jieshou(info.id)" >
				    {{ $t("接受") }}
				  </span>&nbsp;&nbsp;
				  <span style="color: #0055AF;min-width: 80px;line-height: 30px;border-radius: 50px;display: inline-block;height: 30px;line-height:30px;text-align: center;background-color: #F3CC2E"  @click="jujue(info.id)">
				    {{ $t("拒绝") }}
				  </span>
				  </div>
				</div>
				
			
			<div v-if="info.status == 1 && info.type == 1" style="color: #A31515">{{$t("已接受")}}</div>
			<div v-if="info.status == 2 && info.type == 1" style="color: #A31515">{{$t("已拒绝")}}</div>
			<div v-if="info.status == 3 && info.type == 1" style="color: #A31515">{{$t("已过期")}}</div>
	  </div>
    </div>
	<div class="footer" >
	  <van-tabbar v-model="active" route>
	    <van-tabbar-item
	      v-for="item in tabbar"
	      :key="item.title"
	      :to="item.url"
	    >
	      <span>{{ item.title }}</span>
	      <template #icon="props">
	        <img :src="props.active ? item.iconA : item.icon" alt="" />
	      </template>
	    </van-tabbar-item>
	  </van-tabbar>
	</div>
    <ROBOT></ROBOT>
  </div>
</template>

<script>
import ROBOT from '@/components/robot'
// import HBBTN from '@/components/hb-btn'
import { NavBar, Toast } from 'vant'
import {
  apihuoqumsg,
  apimsgread,
  getUrlKey,
  apiconfirm,
  apirefuse
} from '@/http/api/'
export default {
  name: 'welcome',
  components: {
    ROBOT
  },
  data() {
    return {
      name: '',
      phone: '',
      userName: '',
      inputData: '',
      token: '',
      id: '',
      info: []
    }
  },
  created() {
    var url = window.location.href
    let id = getUrlKey('id', url)
    var token = localStorage.getItem('key')
    console.log('token', token)
    if (!token) {
      this.$router.replace('/login')
    }
    this.token = token
    this.id = id
    apimsgread({
      id: id,
      userid: token
    }).then((res) => {
      this.info = res.info
    })
  },

computed: {
	  tabbar() {
	    return [
	      {
	        title: this.$i18n.t('tabbar.首页'),
	        icon: require('@/assets/tabbar/1.png'),
	        iconA: require('@/assets/tabbar/1-1.png'),
	        url: '/home'
	      },
	      {
	        title: this.$i18n.t('tabbar.市场'),
	        icon: require('@/assets/tabbar/2.png'),
	        iconA: require('@/assets/tabbar/2-2.png'),
	        url: '/market'
	      },
	   
	      {
	        title: this.$i18n.t('tabbar.团队'),
	        icon: require('@/assets/tabbar/4.png'),
	        iconA: require('@/assets/tabbar/4-4.png'),
	        url: '/team'
	      },
	      {
	        title: this.$i18n.t('tabbar.我的'),
	        icon: require('@/assets/tabbar/5.png'),
	        iconA: require('@/assets/tabbar/5-5.png'),
	        url: '/my'
	      },
			// {
			//   title: this.$i18n.t('客服'),
			//   icon: require('@/assets/tabbar/3.png'),
			//   iconA: require('@/assets/tabbar/3-3.png'),
			//   url: '/my'
			// }
	    ]
	  }
	},
  methods: {
	   jujue(id){
	  		  Toast.loading({
	  		    duration: 0,
	  		    message: "",
	  		    forbidClick: true,
	  		  });
	  		  apirefuse({
	  		  id: id,
	  		  userid:this.token
	  		  }).then((res) => {
	  		  console.log(res.info);
	  		  			Toast.clear();
	  		  			if(res.status == 1){
	  		  				setTimeout(() => {
	  		  				  Toast.success(this.$t(res.message));
	  		  				  this.$router.go(0);
	  		  				}, 2000);
	  		  				
	  		  			}else{
	  		  				
	  		  				Toast.fail(this.$t(res.info))
	  		  			}
	  		  
	  		  });
	  	  },
	  	  jieshou(id){
	  		  Toast.loading({
	  		    duration: 0,
	  		    message: "",
	  		    forbidClick: true,
	  		  });
	  		  apiconfirm({
	  		  id: id,
	  		  userid:this.token
	  		  }).then((res) => {
	  		  console.log(res.info);
	  			Toast.clear();
	  			if(res.status == 1){
	  				setTimeout(() => {
	  				  Toast.success(this.$t(res.message));
	  				  this.$router.go(0);
	  				}, 2000);	
	  			}else{
	  				Toast.fail(this.$t(res.info))
	  			}
	  		  
	  		  });
	  	  },
    confirmEvent() {
      console.log('ceshi')
    },
    getBack() {
      this.$router.back()
    }
  }
}
</script>

<style lang="scss" scoped>
.page {
  padding: 0 30px 40px 40px;
  .header {
    padding: 0 10px;
    height: 56px;
    line-height: 56px;
    display: flex;
    justify-content: space-between;

    .img {
      width: 40px;
    }
    .logg {
      font-size: 40px;
      font-family: PingFang SC-中粗体, PingFang SC;
      font-weight: normal;
      color: #ffffff;
    }
  }
  .contnet {
    margin-top: 90px;
    width: 100%;
    border-radius: 20px;
    background-color: #2a2c2e;
	.robot-card {
    position: relative;
    padding: 1.36232rem;
    font-size: 0.33816rem;
    border-radius: 0.24155rem;
    margin-bottom: 0.28986rem;
    border-radius: 0.09662rem;
    box-shadow: 0 0.16908rem 0.33816rem -0.24155rem rgb(0 0 0 / 75%);
    background: #f3f6fb;
    .rob-card-title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: #d5ff89;
    }
    .rob-card-body {
      padding: 0.24155rem 0;
      .rob-info {
        color: #d5ff89;
        line-height: 1.2;
        margin-bottom: 0.24155rem;
      }
      .rob-item {
        line-height: 1.8;
      }
    }
    .rob-card-foot {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      margin-top: 0.48309rem;
    }
  }
    .card {
		.robot-card {
		    position: relative;
		    padding: 0.36232rem;
		    font-size: 0.33816rem;
		    border-radius: 0.24155rem;
		    margin-bottom: 0.28986rem;
		    border-radius: 0.09662rem;
		    box-shadow: 0 0.16908rem 0.33816rem -0.24155rem rgb(0 0 0 / 75%);
		    background: #f3f6fb;
		    .rob-card-title {
		      display: flex;
		      align-items: center;
		      justify-content: space-between;
		      color: #d5ff89;
		    }
		    .rob-card-body {
		      padding: 0.24155rem 0;
		      .rob-info {
		        color: #d5ff89;
		        line-height: 1.2;
		        margin-bottom: 0.24155rem;
		      }
		      .rob-item {
		        line-height: 1.8;
		      }
		    }
		    .rob-card-foot {
		      display: flex;
		      align-items: center;
		      justify-content: flex-end;
		      margin-top: 0.48309rem;
		    }
		  }
      padding: 90px 26px 40px;
      position: relative;
		
		  margin-top: 20px;
		  // display: flex;
		  .natixis {
		  
		    display: flex;
		    .spec {
		      color: #888;
		      min-width: 500px;
		      margin-right: 30px;
			  line-height: 30px;
			  padding-top: 10px;
		    }
		  }
		
      img {
        position: absolute;
        left: 50%;
        top: -0px;
        transform: translate(-50%, -50%);
        width: 120px;
        height: 120px;
      }
      .title {
        font-size: 28px;
        line-height: 50px;
        color: #fff;
        margin-bottom: 50px;
      }
      .text {
        color: #fff;
        letter-spacing: 1px;
        line-height: 40px;
        font-size: 20px;
        margin-bottom: 50px;
      }
    }
    .description {
      color: #0d9135;
      line-height: 30px;
      letter-spacing: 1px;
    }
  }
}
</style>
