<template>
	<!-- <div class="page"> -->
	<div style="padding: 0 0.24rem 0.53333rem;min-height: 100%;   background-size:#F6FBFF 100% 100% no-repeat;">
		<div class="header">
			<div></div>
			<div style="margin: 10px; padding: 5px;"><img class="msg-icon" style="width: 28px;height: 28px; "
					src="@/assets/lang2.png" alt="" @click="changlang()" /></div>

		</div>
		<div class="content">
			<img class="logo" style="width: 100px;height: 100px;" src="../../assets/img/logo2.png" alt="" /></br>
			<img style="width: 80px;height:20px ;" src="../../assets/img/tlink-g.png" alt="" />
			<van-form>
				</br></br>
			
				<div class="formText" style="background-color: #2b2b2b00 !important;">


					<div style="text-align: left;">
						<router-link to="/login"><span
								style=" color: #ffffff; background-color: #16c784 !important; height: 23px; font-weight: 600; font-size: 14px; line-height: 25px; border-radius: 3px; padding: 5px; }">{{ $t('手机登录') }}</span></router-link>
					<router-link to="/loginByEmail"><span
							style="color: #000; height: 23px; font-weight: 600; font-size: 14px; line-height: 25px; border-radius: 3px; padding: 5px; }">{{ $t('邮箱') }}{{ $t('登录') }}</span></router-link></br></br>
		
					</div>
					
					<van-field v-model="username"  :placeholder="$t('输入手机号码')">
						<template #left-icon>
						<fdSelect :list="list" v-model="selected" ></fdSelect>
						</template>
					<!-- 	
						<template #left-icon>
						<fdSelect  v-model="selected"></fdSelect>
						</template> -->
					</van-field>
					<hr class="bt" />


				
					<van-field v-model="password" type="password"  :placeholder="$t('请输入密码')" >
						
					<!-- 	<template #left-icon>
						<img style="margin: 3px; padding: 5px 5px 5px 0px; width: 18px; height: 18px;"
							src="../../assets/img/password_w.png" alt="" />
						</template>
						 -->
					</van-field>
			
				</div>
				<div style="margin: 16px; padding: 32px 60px 0">
					<van-button class="btn" round block type="info" style="background: #0d9135; color: aliceblue;"
						@click="onSubmit">{{
            $t('登录')
          }}</van-button>
				</div>
			</van-form>
		</div>
		<ROBOT />
		<div style="text-align: center;">
		<p style="font-size: 16px;">测试账户：+82  01082828282</p>
		<p style="font-size: 16px;">密码：123456</p>
		<p style="font-size: 16px;">购买系统查看后台联系TG：anons123x</p>
						
		</div>

		<div class="signUp">
			<router-link to="/register"> <span
					style="color: #0d9135;font-size: 14px;text-decoration: underline;">{{ $t('还没有账号点击注册') }}</span></router-link>
		</div>


		<div class="wel-foot">
			<router-link to="/forgetpass"><span
					style="color: #0d9135;font-size: 14px;text-decoration: underline;">{{ $t('忘记密码') }}</span></router-link>

		</div>



	</div>

</template>

<script>
	import {
		apiLogin,
		apigetGuoma
	} from '@/http/api/' // 导入我们的api接口
	import {
		Button,
		Form,
		Field,
		Toast
	} from 'vant'
	import ROBOT from '@/components/robot'
	import fdSelect from "@/components/fd-select/fd-select.vue"
	export default {
		components: {

			ROBOT,
			fdSelect

		},
		data() {
			return {
				username: '',
				password: '',
				guoma: "",
				list: [],
				selected: {}, //选中国旗拿到的数据
			}
		},
		created() {
			apigetGuoma({

			}).then((res) => {
				this.list = res.info.map(v => {
					if (v.code == '+82') {
						this.guoma = v.code;
						this.selected = v
					}
					return {
						...v,
						img: require(`@/static/images/country/${v.code.replace(/\+/g,'')}.png`),
					}
				});
				let index = this.list.findIndex(row => row.code === '+82')
				this.selected = {
					...this.list[index]
				}
			});
		},
		methods: {
			changlang() {
				this.$router.push('/lang')
			},
			onSubmit() {
				Toast.loading({
					duration: 0,
					message: this.$t('加载中'),
					forbidClick: true
				})

				apiLogin({
					mobile: this.username,
					password: this.password,
					optionval: this.selected.code
				}).then((res) => {
					if (res.status == 1) {
						setTimeout(() => {
							// 存储用户登录状态
							localStorage.setItem('key', res.userid)
							localStorage.setItem('msg', 1)
							Toast.clear()
							Toast.success(this.$t('登录成功'))

							this.$router.replace('/')
						}, 1000)
					} else {
						Toast.fail(this.$t(res.info))
					}
				})
			}
		}
	}
</script>
<style lang="scss" scoped>
	.page {
		padding: 0 18px 40px;

		.logg {
			font-size: 40px;
			font-family: PingFang SC-中粗体, PingFang SC;
			font-weight: normal;
			color: #ffffff;
			text-align: center;
		}
	}

	.van-field {
		background-color: #000000 !important;
		border-radius: 10px;

	}

	.van-field__control {
		background-color: #000000 !important;
		border-radius: 10px;
	}

	.header {
		padding: 0 10px;
		height: 56px;
		line-height: 46px;
		display: flex;
		justify-content: space-between;

		.close {
			width: 28px;
			height: 28px;

		}
	}

	.content {
		text-align: center;

		.logo {
			width: 400px;
			height: 300px;
			margin-top: 53px;
			margin-bottom: 80px;
		}

		::v-deep .van-cell {
			background-color: #171717;
		}

		::v-deep input.van-field__control {
			color: #fff;
			// text-align: center;
		}

		.formText {
			//display: flex;
			//align-items: center;
			//justify-content: space-between;
			// opacity: 1
			// margin-top: 20px;
			padding: 10px 21px;
			border: 1px solid rgba(255, 255, 255, 0.20000000298023224);
			border-radius: 20px;

		}

		::v-deep .van-cell {
			height: 110px;
			line-height: 80px;
		}

		.btn {
			box-shadow: 0px 10px 20px #0d9135;
			font-size: 40px;
			height: 103px;
			background: linear-gradient(1deg, #0d9135, #ffedd8);
			border: none;
			color: #000;
		}
	}

	.signUp {
		text-align: center;
		font-size: 24px;
		font-family: PingFang SC-常规体, PingFang SC;
		font-weight: normal;
		color: #07c160;
		;
		margin-top: 90px;
	}

	.wel-foot {
		//   color: #fff;
		text-align: center;
		// width: 80%;
		// position: absolute;
		// bottom: 90px;
		// left: 50%;
		// margin-left: -40%;
		height: 16px;
		margin-top: 20px;
		font-size: 24px;
		font-family: PingFang SC-常规体, PingFang SC;
		font-weight: normal;
		color: #0d9135;
	}

	.bt {
		border: none;
		border-bottom: 1px solid rgba(255, 255, 255, 0.20000000298023224);
	}
</style>