<template>
    <div class="page">
		</br>
        <div class="header">
            <div @click="getBack">
                <img class="img" src="../../assets/img/arrow.png" style="width: 25px;height: 25px;" alt=""/>
            </div>
            <div class="logg" style="color: #000;font-weight: bold;">{{ $t('上传凭证') }}</div>
            <div>
            </div>
        </div>
		<div class=" mt24 ml32 mr32" style="height: 0; opacity: 2; border: 0.01px solid #4CAF50;"></div>
        <div class="mt30">
            <div class="contnet">
                <div class="card">
                    <div id="qrcode" ref="qrcode" class="qrCode"></div>

                    <p style="color: #fff; font-size: 14px;text-align: center;" @click="linkEvent">
                        {{ link }}
						</br></br>
						<van-button
						        :data-clipboard-text="link"
						        class="clipboardBtnUrl determine"
						        style="width: 100px; font-size: 14px;color: #fff;height: 25px;text-align: center;"
						        @click="onCopy($t('复制成功'))"
						>
						    {{ $t('复制') }}
						</van-button>
                    </p>
                  <!--  <van-button
                            :data-clipboard-text="link"
                            class="clipboardBtnUrl determine"
                            style="width: 100px; color: #fff;height: 25px;text-align: center;"
                            @click="onCopy($t('复制成功'))"
                    >
                        {{ $t('复制') }}
                    </van-button> -->

                    <p style="color: #888" class="mt50">{{ zhuwang }}</p>
                    <p class="text">{{ $t('充值金额') }}</p>
                    <van-field class="input" v-model="money"/>
                    <p class="text">Transaction ID</p>
                    <van-field
                            class="input"
                            v-model="hash"
                            :placeholder="$t('充值ID提示')"
                    />
                    <p class="text">{{ $t('请上传付款截图') }}</p>
                    <div class="rob-foot-flex">
                        <input type="file" accept="image/*" @change="changeImage()" ref="avatarInput"
                               style="display:none">
                    </div>
                    <img src="../../assets/upload.jpg" style="width: 100px;height: 100px;" @click="upLoad">
                    <div class="text">
                        <div class="pic_list_box" v-if="img">
                            <img :src="img" width="100%">
                        </div>
                    </div>
                    <div class="introduce">
                        {{
                            $t(
                                    '请确保钱包地址无误，如因钱包地址填写错误导致无法收款而造成的损失，将有用户自己承担。'
                            )
                        }}
                    </div>
                </div>
            </div>
        </div>

        <ROBOT></ROBOT>
        <HBBTN @clickEvent="subchongzhi"><span>{{ $t('确认') }} </span></HBBTN>
    </div>
</template>

<script>
import ROBOT from '@/components/robot'
import HBBTN from '@/components/hb-btn'
import axios from "axios";
import QS from "qs";
import Clipboard from 'clipboard'
import QRCode from "qrcodejs2-fix";
import {NavBar, Progress, Icon, Button, Toast} from 'vant'

import {
    apichongzhi,
    getUrlKey,
    apichongzhiusdt,
    apiuploadimg,
    postimg,
    rechargeUsdt
} from '@/http/api/'


export default {
    name: 'welcome',
    components: {
        ROBOT,
        HBBTN
    },
    data() {
        return {
            name: '',
            userName: '',
            inputData: '32',
            active: 2,
            fileList: [], //{ url: 'https://img01.yzcdn.cn/vant/leaf.jpg' }
            token: '',
            link: '',
            zhuwang: '',
            hash: '',
            money: '',
            money2: '',
            dataimg: '',
            imgDatas: [],
            img: ""
        }
    },
    created() {
        const token = localStorage.getItem('key')
        console.log('token', token)
        if (token == null) {
            this.$router.replace('/login')
        }
        var url = window.location.href
        let type = getUrlKey('type', url)
        let money = getUrlKey('money', url)
        let money2 = getUrlKey('money2', url)
        this.money = money
        this.token = token
        this.money2 = money2
        this.init(type)
        if (type == 1) {
            this.zhuwang = 'ERC20'
        } else if (type == 2) {
            this.zhuwang = 'TRC20'
        } else if (type == 3) {
            this.zhuwang = 'TRX'
        }
    },
    methods: {
        subchongzhi() {
            if (this.hash == '') {
                Toast.fail("hash" + this.$t('不能为空'));
                return;
            }
            if (!this.dataimg) {
                Toast.fail(this.$t('请上传付款截图'));
                return;
            }
            Toast.loading({
                duration: 0,
                message: "",
                forbidClick: true,
            });
            apichongzhiusdt({
                userid: this.token,
                hash: this.hash,
                zhuwang: this.zhuwang,
                money: this.zhuwang == 'TRX'? this.money2 :this.money,
                img: this.dataimg
            }).then((res) => {
                Toast.clear();
                if (res.status == 1) {
                    // setTimeout(() => {
                        Toast.success(this.$t(res.info));
                        this.$router.push("/recharge");
                    // }, 1000);
                } else {
                    Toast.fail(res.info);
                    return;

                }

            })
        },
        /**
         * post方法，对应post请求
         * @param {String} url [请求的url地址]
         * @param {Object} params [请求时携带的参数]
         */
        postdata(url, params) {

            return new Promise((resolve, reject) => {
                axios
                    .post(url, QS.stringify(params))
                    .then((res) => {
                        resolve(res.data);
                    })
                    .catch((err) => {
                        reject(err.data);
                    });
            });
        },
        upLoad() {
            this.$refs.avatarInput.dispatchEvent(new MouseEvent("click"));
        },
        changeImage() {
            var files = this.$refs.avatarInput.files;
            var that = this;

            function readAndPreview(file) {
                //Make sure `file.name` matches our extensions criteria
                if (/\.(jpe?g|png|gif)$/i.test(file.name)) {
                    var reader = new FileReader();
                    reader.onload = function (e) {
                        // 防止重复上传
                        // if (that.imgDatas.indexOf(e.target.result) === -1) {
                        //   that.imgDatas.push(e.target.result);
                        // }
                    };
                    reader.readAsDataURL(file);
                }
            }

            readAndPreview(files[0])
            if (files.length === 0) {
                return;
            }

            this.setUploadFile(files[0])
        },
        setUploadFile(file) {
            Toast.loading({
                duration: 0,
                message: "",
                forbidClick: true,
            });


            var formData = new FormData()
            formData.append(file.name, file);// 添加到请求体

            postimg('Home/Home/headimg_img', formData)

                .then(data => {
                    Toast.clear();
                    //let data = JSON.parse(res.bodyText);
                    if (data.status == 1) {
                        this.img = data.info;
                        this.dataimg = data.info2;
                    } else {
                        Toast.fail(this.$t(data.info));
                    }
                    console.log(data);
                })
        },

        onCopy(text) {
            let clipboard = new Clipboard('.clipboardBtnUrl')
            clipboard.on('success', function (e) {
                Toast(text)
                e.clearSelection()
            })
        },
        confirmEvent() {
            console.log('ceshi')
        },
        getBack() {
            this.$router.back()
        },
        linkEvent() {
            console.log('链接')
        },
        afterRead(file) {
            // 此时可以自行将文件上传至服务器
            console.log(file)
        },
        init(type) {
            rechargeUsdt({
                userid: this.token,
                type: type
            }).then((res) => {
                this.$refs.qrcode.innerHTML = ''
                console.log(res)
                this.link = res.info
                new QRCode('qrcode', {
                    width: 250, // 二维码宽度，单位像素
                    height: 250, // 二维码高度，单位像素
                    text: res.info // 生成二维码的链接
                })
            })
        }
    }
}
</script>

<style lang="scss" scoped>
.page {
  padding: 0 30px 40px 40px;

  .header {
    padding: 0 10px;
    height: 56px;
    line-height: 56px;
    display: flex;
    justify-content: space-between;

    .img {
      width: 40px;
    }

    .logg {
      font-size: 40px;
      font-family: PingFang SC-中粗体, PingFang SC;
      font-weight: normal;
      color: #ffffff;
    }
  }

  .contnet {
    // margin-top: 90px;
    // width: 100%;
    border-radius: 20px;
    background-color: #2a2c2e;

    .card {
      margin-bottom: 40px;
      padding: 50px 26px 40px;
      position: relative;

      .qrCode {
        text-align: -webkit-center;

        img {
          width: 200px;
          height: 200px;
        }
      }

      .determine {
        border-radius: 15px;
        padding: 10px 0;
        text-align: -webkit-center;
        font-size: 12px;
        margin-bottom: 50px;
        background-color: #0d9135;
      }

      .text {
        color: #ffffff;
      }

      .uplod {
        margin: 20px 0;
      }

      .introduce {
        color: #888;
      }
    }

    .description {
      color: #0d9135;
      line-height: 30px;
      letter-spacing: 1px;
    }
  }

  ::v-deep .van-uploader__upload {
    background-color: #2a2c2e;
    border: 1px solid #888;
    border-radius: 10px;
  }
}
</style>
