<template>
    <div>
        <layout :isShowFooter="true">
            <template #title>
                <div class="logg" style="color: #000;font-weight: bold;"> {{ $t('付款方式') }}</div>
            </template>
            <template #right>
                <img class="addIcon" src="@/assets/icon/add.png" alt="" @click="bindbank"/>
            </template>
            <template #content>
                <div class="content">
                    <van-cell v-for="item in list" :key="item.id">
                        <div class="card">
                            <div class="rowData">
                                <!-- <p>{{item.cardtype}}</p> -->
                                <p style="margin-left: 130px;color: #fff;">{{ $t('钱包地址') }}</p>
                            </div>
                            <!-- 		<img :src="require(`@/assets/icon/${item.bank}.png`)" style="width: 20px;height: 20px;"  alt=""/> -->
                           <div style="display: flex; align-items: center;justify-content: space-between">
                               <img :src="item.img" alt="" style="width: 40px; height: 40px;"/>
                               <div class="cardNumber" style="color: red;font-size: 14px;margin-left: 40px;">{{ item.bank }}</div>
                               </br>
                               <p @click="alertBank(item.id, item.type)"
                                  style="color: rgb(255, 255, 255); background-color: #07C160 !important; padding-right: 8px; padding-left: 8px; height: 23px; font-weight: 600; font-size: 12px; line-height: 25px; border-radius: 3px;">
                                   {{ $t('修改') }} </p>
                           </div>
                            <div class="rowData">
                                <p>{{ item.name }}</p>
                                <p style="margin-right: 10px;color: #fff;font-size: 14px;">{{ item.bankcard }}</p>
                            </div>
                        </div>
                    </van-cell>
                </div>
                <van-popup v-model="tishi" round closeable @close="closetishi" class="toastIndex">
                    <div class="wt-select-dialog" style=" height: 5.00097rem;text-align: center;background-color: #2a2c2e"></br></br>
                        <br>
                        <p style="color: #FFFFFF;text-align: center;z-index: 10; font-size: 18px; ">{{ $t("请先设置支付密码") }}</p>
                        <van-row>
                            <div>
                                <van-col span="12" style="width: 60%; padding-top: 20px;" @click="gosetting">
                                    <van-button type="info" style="width: 5.5rem;background-color: #2a2c2e">{{ $t("去设置") }}</van-button>
                                </van-col>
                            </div>
                        </van-row>
                    </div>

                </van-popup>
            </template>
        </layout>
        <ROBOT></ROBOT>
    </div>
</template>

<script>
import ROBOT from '@/components/robot'
import {NavBar, Toast, Popup, Row, Button} from "vant";
import {apiUser_bank_mas, apidelbank, apiVerifyPaypass, apiUser_mas2} from "@/http/api/";

export default {
    components: {
        ROBOT
    },
    data() {
        return {
            list: [],
            loading: false,
            finished: false,
            info2: [],
            tishi: false
        }
    },
    created() {
        const token = localStorage.getItem("key");
        console.log("token", token);
        if (token == null) {
            this.$router.replace("/login");
        }
        this.token = token;
        apiUser_bank_mas({
            userid: token,
        }).then((res) => {

            this.list = res.user;

        });
        apiUser_mas2({
            userid: token,
        }).then((res) => {
            this.info2 = res.user;
            // 判断是否设置交易密码
            if (res.user.paypassword == "") {
                this.tishi = true;
            }
        });
    },
    methods: {
        closetishi() {
            this.tishi = false;
        },
        gosetting() {
            this.$router.push('/setPwd');
        },
        alertBank(id, type) {
            if (type != 'BANK') {
                this.$router.push("/bindingPurse");
            } else {
                this.$router.push("/bankset?id=" + id);
            }
        },
        bindbank() {
            this.$router.push("/bindingPurse");
        }
    }
}
</script>

<style lang="scss" scoped>
.addIcon {
  width: 42px;
  height: 42px;

}

.content {
  margin-top: 44px;
  //   padding: 0 10px;
  box-sizing: border-box;

  .card {
    position: relative;
    // background: url('@/assets/icon/bg1.png');
    background-color: #1E1E1E;
    border-radius: 20px;

    // padding: 38px 34px;
    padding: 0 20px;

    .rowData {
      display: flex;
      text-align: center;
      justify-content: space-between;
    }

    .cardNumber {
      font-size: 40px;
      font-weight: 700;


    }
  }
}

// .nodata {
//   margin-top: 300px;
//
// }
</style>
