<template>
    <div class="page">
       <div class="header">
       	<div @click="getBack" style="padding-top: 6px;">
       	   <img class="img" src="../../assets/img/arrow.png" style="width: 25px;height: 25px;" alt=""/>
       	</div>
       	<div style="margin: 10px; padding: 5px;"><img class="msg-icon" style="width: 28px;height: 28px; "
       			src="@/assets/lang2.png" alt="" @click="changlang()" /></div>

       </div>
       <br>
       <div style="text-align: center;">
       <img class="logo" style="width: 100px;height: 100px;" src="../../assets/img/logo2.png" alt="" /></br>
       <img style="width: 80px;height:20px ;" src="../../assets/img/tlink-g.png" alt="" />
       </div>
        <div style="width: 50%;height: 0.7rem; margin-top: 40px;">
            <van-tabs type="card" background="transparent" title-inactive-color="#000" v-model="activeTab"
                @click="tabChange">
                <van-tab :title="$t('手机号码')" name="mobile"></van-tab>
                <van-tab :title="$t('邮箱')" name="email"></van-tab>
            </van-tabs>
        </div>
        <div class="content">
            <van-form>

                <van-field class="input" v-model="username" :placeholder="$t('请输入邮箱')">

                </van-field>
                <van-field class="input" v-model="code" :placeholder="$t('输入验证码')">
                    <template #button>
                       <div v-if="!codeDisabled">
                           <van-button style="height: 32px; padding: 0 8px; font-size: 12px; vertical-align: top;" size="small" type="primary" @click="onSelect({ type: 'email' })"
                                       :disabled="codeDisabled">
                               {{ $t('获取验证码') }}
                           </van-button>
                       </div>
                        <div style="color: #FFFFFF" v-else>
                            <van-button style="height: 32px; padding: 0 8px; font-size: 12px; vertical-align: top;" size="small" type="primary" disabled>
                                {{ codeTime }}
                            </van-button>
                        </div>
                    </template>
                </van-field>
                <!-- <div class="captcha" v-show="smswithc">
                <van-field v-model="captcha" :placeholder="$t('输入验证码')" />
                <van-button class="textBtn" plain type="info" @click="getcode()">
                  <span v-show="show">{{ tishi }}</span>
                  <span v-show="!show">{{ count }} s</span>
                </van-button>
              </div> -->
                <van-field class="input" v-model="password" type="password" :placeholder="$t('输入密码')" />
                <van-field class="input" v-model="password2" type="password" :placeholder="$t('再次输入密码')" />
                <van-field class="input" v-model="invit" :placeholder="$t('请输入邀请码')" />
            </van-form>
        </div>
        <ROBOT />
        <div style="margin: 16px; padding: 32px 60px 0">
            <van-button class="btn" round block type="info" @click="register">{{
                $t('注册')
            }}
            </van-button>
        </div>
        <br>
        <div class="signUp" style="text-align: center;">
            <router-link to="/login"> {{ $t('立即登录') }}</router-link>
        </div>

        <van-action-sheet v-model="showCode" :actions="actions" @select="onSelect" />
    </div>
</template>

<script>
import ROBOT from '@/components/robot'
import { Button, NavBar, Field, DropdownMenu, DropdownItem, Toast } from 'vant'
import { apiRegisterByEmail, getUrlKey, apiRegss, apismssWitch, apiLogin, apigetGuoma } from '@/http/api/' // 导入我们的api接口
import fdSelect from "@/components/fd-select/fd-select.vue";
import { apiRegssByMail, apiSmsCode } from '@/http/api';

export default {
    name: 'welcome',
    components: {
        ROBOT,
        fdSelect,
        [Button.name]: Button,
        [NavBar.name]: NavBar,
        [Field.name]: Field,
        [DropdownMenu.name]: DropdownMenu,
        [DropdownItem.name]: DropdownItem
    },
    data () {
        return {
            activeTab: 'email',
            showCode: false,
            actions: [
                { name: this.$t('手机号码'), type: 'mobile' },
                { name: this.$t('邮箱'), type: 'email' },
            ],
            // prefix: '+234',
            value: '+234',
            mobile: '',
            password: '',
            password2: '',
            captcha: '',
            invit: '',
            code: '',
            username: '',
            smswithc: false,
            option1: [],
            count: 60,
            show: true,
            timer: null,
            tishi: this.$t('获取邮箱验证码'),
            selected: {}, //这是拿到的数据
            codeDisabled: false,
            whereType: 1,
            codeTime: 60,
            timer1: null,
        }
    },

    watch: {
        mobile: function () {
            this.mobile = this.mobile.replace(/[^\d]/g, '')
        }
    },
    mounted () {
        apismssWitch({}).then((res) => {
            if (res.info.sta_tankuang == '1') {
                this.smswithc = true;
            } else {
                this.smswithc = true;
            }

        })
    },
    created (e) {
        var url = window.location.href
        let code = getUrlKey('code', url)
        this.invit = code;
        apigetGuoma({}).then((res) => {
            var that = this;
            // console.log(res);
            var arr = res.info;
            for (let i = 0; i < arr.length; i++) {
                let arr2 = new Array();
                arr2.text = arr[i].code;
                arr2.value = arr[i].code
                this.option1.push(arr2);
                // console.log(this.option1)

            }


            that.value = arr[0].code;
            this.option1 = this.option1.map((v, index) => {
                return {
                    code: v.text,
                    img: require(`@/static/images/country/${v.text.replace(/\+/g, '')}.png`),
                    id: index + ''
                }
            })
            let index = this.option1.findIndex(row => row.code === '+82')
            this.selected = {
                ...this.option1[index]
            }

        });
    },
    methods: {


		changlang() {
			this.$router.push('/lang')
		},

        tabChange (name) {
            if (name == 'mobile') {
                this.$router.replace('/register')
            } else if (name == 'email') {
                this.$router.replace('/registerByEmail')
            }
        },
        onSelect (row) {
            if (row.type == 'mobile') {
                if (!this.mobile) {
                    Toast.fail(this.$t("手机号码不能为空"));
                    return;
                }
                this.whereType = 1
                apiSmsCode({
                    username: this.mobile,
                    type: 'reg'
                }).then((res) => {
                    if (res.status != 0) {
                        this.codeDisabled = true
                        this.timer1 = setInterval(() => {
                            if (this.codeTime > 0 && this.codeTime <= 60) {
                                this.codeTime--;
                            } else {
                                this.codeDisabled = false
                                this.codeTime = 60
                                clearInterval(this.timer1);
                                this.timer1 = null;
                            }
                        },1000)
                        Toast.success(this.$t(res.info));
                    } else {
                        Toast.fail(this.$t(res.info));
                    }

                })
            } else if (row.type == 'email') {
                if (!this.username) {
                    Toast.fail(this.$t("请输入邮箱"));
                    return;
                }
                this.whereType = 2
                apiRegssByMail({
                    username: this.username,
                    type: 'reg',
                }).then((res) => {
                    if (res.status != 0) {
                        this.codeDisabled = true
                        this.timer1 = setInterval(() => {
                            if (this.codeTime > 0 && this.codeTime <= 60) {
                                this.codeTime--;
                            } else {
                                this.codeDisabled = false
                                this.codeTime = 60
                                clearInterval(this.timer1);
                                this.timer1 = null;
                            }
                        },1000)
                        Toast.success(this.$t(res.info));
                    } else {
                        Toast.fail(this.$t(res.info));
                    }
                })
            }
            this.showCode = false
        },
        // getVerfCode() {
        //     if (!this.username) {
        //         Toast.fail(this.$t("请输入邮箱"));
        //         return;
        //     }
        //
        //     this.codeDisabled = true
        //     apiRegssByMail({
        //         username: this.username,
        //         type: 'reg',
        //     }).then((res) => {
        //         if (res.status != 0) {
        //             Toast.success(this.$t(res.info));
        //         } else {
        //             Toast.fail(this.$t(res.info));
        //         }
        //         this.codeDisabled = false
        //     }).catch(err =>{
        //         this.codeDisabled = false
        //     })
        // },
        isEmail (str) {
            return /^[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/.test(str);
        },
        getCode2 () {
            const TIME_COUNT = 60
            if (!this.timer) {
                this.count = TIME_COUNT
                this.show = false
                this.timer = setInterval(() => {
                    if (this.count > 0 && this.count <= TIME_COUNT) {
                        this.count--
                    } else {
                        this.show = true
                        clearInterval(this.timer)
                        this.timer = null
                    }
                }, 1000)
            }
        },
        getcode () {
            console.log(this.selected, 888888)
            if (!this.mobile) {
                Toast.fail(this.$t('手机号码不能为空'))
                return
            }
            if (!this.username) {
                Toast.fail(this.$t('账号不存在'))
                return
            }
            if (!this.code) {
                Toast.fail(this.$t('输入验证码'))
                return
            }

            Toast.loading({
                duration: 0,
                message: this.$t('加载中'),
                forbidClick: true
            })
            apiRegss({
                username: this.username,
                mobile: this.mobile,
                type: 'reg',
                optionval: this.selected.code
            }).then((res) => {
                Toast.clear()
                console.log(res)
                if (res.status) {
                    Toast.success(this.$t(res.info))
                    this.getCode2()
                } else {
                    Toast.fail(this.$t(res.info))
                }
            })
        },
        register () {
            console.log(this.selected, 55555)
            let form = {
                invit: this.invit,
                mobile: this.mobile,
                password: this.password,
                password2: this.password2,
                username: this.username,
                code: this.code,
                optionval: this.selected.code,
                whereType: this.whereType // 1 手机 2邮箱
            }
            Toast.loading({
                duration: 0,
                message: this.$t('加载中'),
                forbidClick: true
            })
            apiRegisterByEmail(form).then((res) => {
                console.log(res)
                if (res.status == 1) {
                    Toast.clear()
                    setTimeout(() => {
                        // 存储用户登录状态
                        Toast.success(this.$t('注册成功'))
                        apiLogin({
                            mobile: this.mobile,
                            password: this.password,
                            optionval: this.selected.code
                        }).then((res) => {
                            localStorage.setItem('key', res.userid)
                            localStorage.setItem('msg', 1)

                            this.$router.replace('/')
                        })

                    }, 1500)
                } else {
                    Toast.fail(this.$t(res.info))
                }
            })
        },

        captchaEvent () {
            this.textContent = '12miao'
        },
        getBack () {
            this.$router.back()
        },
        onSubmit () {
            console.log('zhuce ')
        }
    }
}
</script>

<style lang="scss" scoped>
::v-deep .van-tabs__nav--card .van-tab {
    border-right: none !important;
}

::v-deep .van-tabs__nav--card {
    border: none !important;
}

::v-deep .van-tab--active {
    background-color: #029686 !important;
    border-left-width: 0 !important;
    border: none !important;
}

.page {
    .van-dropdown-menu__bar {
        background-color: #2b2b2b !important;
    }

    .header {
        padding: 0 10px;
        height: 56px;
        line-height: 56px;
        display: flex;
        justify-content: space-between;

        .close {
            width: 28px;
            height: 28px;
        }

        .img {
            width: 40px;
        }

        .logg {
            font-size: 40px;
            font-family: PingFang SC-中粗体, PingFang SC;
            font-weight: normal;
            color: #ffffff;
        }
    }

    .van-field {
        background-color: #2b2b2b !important;
    }

    .van-field__control {
        background-color: #2b2b2b !important;
    }

    .van-dropdown-menu .van-dropdown-menu__bar {
        background-color: #2b2b2b !important;
    }

    .btn {
          box-shadow: 0 0.13333rem 0.26667rem #0d9135;
          font-size: 0.53333rem;
          height: 1.37333rem;
          background: #0d9135;
          color: #fff;
    }

    .phone {
        display: flex;
    }

    .captcha {
        height: 90px;
        padding: 10px;
        margin-bottom: 30px;
        display: flex;
        border: 1px solid rgba(255, 255, 255, 0.20000000298023224);
        border-radius: 20px;

        .textBtn {
            width: 500px !important;
        }

        ::v-deep .van-button--plain.van-button--info {
            color: #0d9135;
        }
    }

    .content {
        margin-top: 40px;
        padding: 0 36px;

        .prefix {
            width: 180px;
            margin-right: 30px;

            ::v-deep input.van-field__control {
                color: #0d9135;
            }
        }
    }

    ::v-deep .van-button--plain {
        background-color: #2b2b2b;
    }

    ::v-deep.van-button--info {
        border: none;
    }

    ::v-deep .van-dropdown-menu__bar {
        height: 110px;
    }
}
</style>
