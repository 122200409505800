export default {
  tabbar: {
    首页: 'Casa',
    市场: 'Mercado',
    任务: 'Tarea',
    团队: 'Equipo',
    我的: 'Mi'
  },
  navbar: {
    充值: 'Depósito',
    提现: 'Retiro',
    帮助: 'Ayudar',
    分享: 'Invitar',
    活动: 'Bono'
  },
  payment: {
    付款方式详情: 'Detalles del método de pago',
    银行: 'Banco',
    钱包地址: 'Dirección de billetera',
    交易密码: 'Contraseña de transacción',
    保存: 'Guardar'
  },
  market: {
    市场: 'Mercado',
    名称: 'Nombre',
    价格: 'Precio',
  },

  task: {
    任务: 'Tarea',
	语言切换: 'Cambio de idioma',
    我的余额: 'Mi balance',
    增加订单数量: 'Aumentar la cantidad del pedido',
    今日剩余订单: 'Pedidos restantes hoy',
    频率: 'frecuencia',
    手动交易: 'Comercio manual',
    预期收益: 'Ingreso esperado',
    需要启动存款: 'Necesito comenzar a depositar',
    任务说明: 'Descripción de la tarea',
    每天可以完成10个任务: 'Puede completar 10 tareas por día.',
    联系VIP助手增加任务数量: 'Contacta con el asistente VIP para aumentar el número de tareas',
    每个用户的任务总数为300次: 'El número total de tareas por usuario es de 300',
    购买机器人无需人工操作即可自动交易: 'Transacción automática sin operación manual al comprar robots',
    我知道了: 'lo conseguí!'
  },
  团队: 'Equipo',
  规则: 'Regla',
  会员级别: 'Nivel de miembro',
  经验: 'Puntos de cuenta',
  代理信息: 'Información del agente',
  更多: 'Más',
  团队奖励: 'Recompensas del equipo',
  昨天: 'El dia de ayer',
  本星期: 'Esta semana',
  全部: 'Todos',
  团队收入记录: 'Récords de ingresos del equipo',
  机器人回扣: 'Comisión de robots',
  会员体验: 'Experiencia de miembro',
  个人收入记录: 'Registros personales de ingresos',
  机器人采购: 'Adquisición de robots',
  机器人启动押金: 'Depósito de inicio de robot',
  机器人礼物: 'Regalo de robot',
  机器人订单: 'Orden de robots',
  机器人: 'Robot',
  一次性总和: 'Suma global',
  总计: 'Total',
  我的机器人: 'Mi robot',
  被激活: 'Activado',
  工作中: 'Trabajando',
  暂停: 'Deténgase',
  结束: 'Estar caducado',
  刷新: 'Refrescar',
  来源: 'Origen',
  价格: 'Precio',
  启动押金: 'Precio de salida',
  剩余时间: 'Tiempo restante',
  激活时间: 'Hora de activación',
  号: 'No.',
  我的资产: 'Mis bienes',
  退出: 'Finalizar la sesión',
  邀请码: 'Invitar código',
  我的钱包余额: 'Saldo de mi billetera',
  我的机器人仓库: 'Mi lista de bots',
  代理信息: 'Información del agente',
  去提款: 'Retirada',
  昨天代理返利: 'Reembolso de agencia ayer',
  累计代理返利: 'Reembolso de agencia acumulado',
  今天机器人收益: 'Ingresos de hoy',
  累计机器人收益: 'Renta acumulada',
  累计机器人回扣: 'Reembolso acumulado',
  累计总回报: 'Retorno total acumulativo',
  自动订单机器人数量: 'Número de robots de pedidos automáticos',
  我的付款方式: 'Mi método de pago',
  个人收入记录: 'Registros personales de ingresos',
  团队收入记录: 'Registros de ingresos del equipo',
  充值记录: 'Registro de recarga',
  我的团队成员: 'Los miembros de mi equipo',
  活动中心: 'Centro de eventos',
  修改登录密码: 'Modificar contraseña de acceso',
  交易密码管理: 'Contraseña de transacción',
  机器人: 'Robot',
  机器人性能: 'Inversión compuesta',
  机器人价格: 'Precio de los robots',

  有效期: 'Ciclo',

  天: 'Días',
  去购买: 'Comprar',
  买个机器人: 'Comprar robot',

  预计日收益: 'Ingreso diario estimado',
  启动保证金: 'Depósito de puesta en marcha',
  请输入购买数量: 'Por favor ingrese la cantidad de compra',
  个机器人: ' PCs. / robot',
  机器人购买后需要激活才能工作: 'El robot debe activarse para funcionar después de la compra.',
  机器人激活需要从余额中扣除相应的启动押金:
    'La activación del robot debe deducir el depósito de inicio correspondiente del saldo',
  机器人暂停机器人停止时将返还启动押金:
    'Cuando el robot se detenga, se devolverá el depósito inicial',
	不激活的机器人可以赠送:"Los robots inactivos se pueden regalar",
  购买: 'Comprar',
  点击去了解我们: 'Entra en la web oficial',
  购买机器人: 'Comprar robot',
  快速入口: 'Entrada rápida',
  '日/个人受益': 'Beneficio diario / personal',
  购买教程: 'Comprar tutoriales',
  订单数量: 'Ordene la cantidad',
  我们的合作伙伴: 'Nuestros compañeros',
  提款: 'Retirada',
  提款金额: 'Cantidad de retiro ',
  你还没有添加银行卡: 'No has añadido una tarjeta bancaria',
  提现说明: 'Descripción del retiro',
  确认提款: 'Confirmar retiro ',
  钱包余额: 'Saldo de la billetera',
  输入数量:"Ingrese la cantidad de USDT",
  数量:"Monto",
  登录: 'Iniciar sesión',
  输入账号: 'Ingrese el número de cuenta',
  输入密码: 'Contraseña de entrada',
  还没有账号点击注册: '¿Sin cuenta? Haga clic en Registrarse',
  忘记密码: 'Olvidaste la contraseña',
  重新登录: 'Ingresar de nuevo',
  密码找回成功: 'Contraseña recuperada con éxito',
  找回成功请重新登录: 'Recuperado con éxito, vuelva a iniciar sesión',
  确认: 'Confirmar',
  发送验证码: 'Enviar el código de verificación',
  再次输入密码: 'Introduce la contraseña de nuevo',
  输入验证码: 'Introduce el código de verificación',
  输入手机号: 'Introduce el número de móvil',
  注册: 'Registrar',
  输入用户名: 'Ingrese un nombre de usuario',
  请输入邀请码: 'Por favor, introduzca el código de invitación',
  返回: 'Regreso',
  密码找回失败: 'Error al recuperar la contraseña',
  输入的信息有误: 'Ingresó información incorrecta',

	获取验证码:"Obtener código",
	手机号码不能为空:"El número de móvil no puede estar vacío",
	账号不存在:"La cuenta no existe",
	发送成功:"Enviado con éxito",
	今日收益:"Ganancias de hoy",

	日收益:"Diaria",

	数量不能为空:"La cantidad no puede estar vacía",
	加载中:"Sobreprima...",
	唯一码:"Código único",
	未激活:"No activo",
	激活:"Activación",
	购买时间:"Hora de compra",
	"钱包余额不足，激活该机器人失败":"Saldo de billetera insuficiente, no se pudo activar el robot",
	暂无数据:"Sin datos...",
	激活时间:"Hora de activación",
	运行时长:"Tiempo laboral",
	签约购买:"Compra de contrato",
	系统赠送:"Regalo del sistema",
	状态:"Estatus",
	正常:"Normalidad",
	我的机器人:"Mi robot",
	一级会员:"Nivel 1",
	二级会员:"Nivel 2",
	三级会员:"Nivel 3",
	人:"Personas",
	充值客服:"Recarga atención al cliente",
	充值:"Depósito",
	提现:"Retiro",
	提款密码:"Contraseña de retiro",
	设置交易密码:"Establecer contraseña de transacción",
	登录密码:"Contraseña de inicio de sesión",
	请输入交易密码:"Por favor ingrese la contraseña de la transacción",
	再次请输入交易密码:"Por favor ingrese su contraseña de transacción nuevamente",
	确认: 'Confirmar',
	手机号码不能为空:"El número de móvil no puede estar vacío",
	两次密码不一致:"Las dos contraseñas son inconsistentes.",
	请输入验证码:"Porfavor ingrese el codigo de verificacion",
	操作成功:"La operación es exitosa",
	"用户名或密码不正确,登录失败":"Nombre de usuario o contraseña incorrectos, error de inicio de sesión",
	登录成功:"Inicio de sesión exitoso",
	充值说明:"Instrucciones de recarga",
	请先设置支付密码:"Primero establece la contraseña de pago",
	复制成功:"Copia exitosa",
	冻结机器人做单本金:"Congelando el principal de los pedidos de robots",
	待审核:"Para ser revisado",
	成功:"Éxito",
	失败:"Fallar",
	审核中:"En revisión",
	在线充值:"Recarga en linea",
	描叙:"Describir",
	银行卡提现:"Retiro con tarjeta bancaria",
	USDT提现:"Retiro de USDT",
	三级代理:"Agente de tercer nivel",
	一级代理:"Agente primario",
	二级代理:"Agente secundario",
	做单:"Hacer un pedido",
	团队返佣: "Comisión de equipo",
	购买机器人返佣:"Comprar comisión de robot",
	本金返还:"Devolución del principal",
	时间:"Hora",
	机器人做单返还:"Robot hace pedido y devuelve",
	涨幅:"Incrementar",

  付款方式:"Método de pago",
	我的留言:"Mi mensaje",

  银行:'BANCO',
  修改: 'Modifica',
 '确保钱包地址正确，钱包以防地址输入错误而收不到钱用户造成的损失':'Asegúrese de que la dirección de la billetera sea correcta, para evitar la pérdida causada por los usuarios que no pueden recibir dinero debido a la entrada incorrecta de la dirección',
 连接借记卡:"Conectar tarjeta bancaria",
 银行名称:'Nombre del banco',
 姓名姓氏: 'Nombre',
 IBAN号码:'Número de tarjeta bancaria',
 '* 重要提示:借记卡信息必须真实有效才能取款。':'* Importante: la información de la tarjeta de débito debe ser verdadera y válida antes del retiro.',
 帮助中心:'Centro de ayuda',
 会员须知:'Notas para los miembros',
 '24小时内使用USDT实时取款（推荐）':'1. Real time withdrawal with usdt within 24 hours (recommended)',
 '银行卡 取款 *取款时间为10:00-18:00 *发起取款后2小时内入账*申请非取款时间或跨行取款将于次日10:00-18:00到账':' 2. bank card withdrawal * withdrawal time is 10:00-18:00 * account entry within 2 hours after the withdrawal is initiated * application for non withdrawal time or inter-bank withdrawal will be received at 10:00-18:00 the next day',
 我知道了:'Entiendo!',
 链接点击:'Haga clic en enlace',

 建立自己的团队邀请:'Crea tu propia invitación de equipo',

 加入:'unirse',
 "你出售的机器人对方已接受,出售所得已打款到您账户,请查收。":
 	    "El robot que vendió ha sido aceptado por la otra parte. El producto de la venta se ha pagado a su cuenta. por favor, compruebe.",
 	"你出售的机器人对方已拒绝接受":"El robot que vendió ha sido rechazado por la otra parte",
 	向你赠送一台机器人:"Se le presenta un robot para su confirmación.",
 	向你出售一台机器人:"Venderle un robot, por confirmar",
 	出售价格不能超过机器人价格:"El precio de venta no puede exceder el precio del robot.",
 	银行卡已被绑定:"La tarjeta bancaria ha sido vinculada",
 	USDT_TRC20提现:'USDT_TRC20 Retiro',
 	USDT_ERC20提现:'USDT_ERC20 Retiro',
 	银行卡提现:'Retiro de tarjeta bancaria',
 	邀请赠送:'Regalo de invitación',
 	机器人返佣:'Comisión de robots',
 	升级:' comprar ',
 	充值主网:'Recarga red principal',
   "您被限制出售机器人,请联系客服了解详情":
     "Tiene restricciones para vender robots. Póngase en contacto con el servicio de atención al cliente para obtener más información.",
   交易编号: "Número de transacción",
   消息详情:"Detalles del mensaje",
   个人收益累计:"Renta personal acumulada",
   今日代理收益:"Ingresos de la agencia hoy",
   代理累计收益:"Renta acumulada de agencia",

   截图保存推荐给朋友:"Invite a amigos para ganar recompensas de comisiones de equipo. La comisión de ingresos de equipos de tercer nivel es del 8%-3%-2%, y se liquida una vez al día después de las 00:00",
   复制:"Copiar",

   请输入正确的充值金额:"Seleccione o introduzca el importe del pago",
   推荐:"Recomendar",
   充值金额:"Cantidad de recarga",
   请上传付款截图:"Sube la captura de pantalla del pago.",
   充值ID提示:"Pegue el número TXID.",
   "请确保钱包地址无误，如因钱包地址填写错误导致无法收款而造成的损失，将有用户自己承担。":"Asegúrese de que la dirección de la billetera sea correcta. Si la dirección de la billetera se completa incorrectamente, el usuario asumirá las pérdidas causadas por la imposibilidad de cobrar el dinero..",
	上传凭证:"Subir comprobante",
	不能为空:"No puede estar vacío",
  绑定钱包地址:'Vincular dirección de billetera',

	备注:"Comentarios",
	备注号码类别:"Número de tarjeta bancaria o CLABE",
	请输入你的名字:"por favor, escriba su nombre",
	请输入银行卡号:"Introduce el número de tarjeta (16 dígitos)",
	银行卡号:"Número de tarjeta bancaria",
	添加银行卡:"Agregar método de retiro",
	请选择银行:"Selecciona Banco",
	请输入钱包地址:"Por favor ingrese la dirección de la billetera",
	钱包地址:"Dirección de la billetera",
	"取款须知":"Instrucciones para el retiro",
	"须知1":"1. Real time withdrawal with usdt within 24 hours (recommended)",
	"须知2":"2.bank card",
	"须知3":"Withdrawal * withdrawal time is 10:00-18:00",
	"须知4":"*The application for non withdrawal time or inter-bank withdrawal will be received at 10:00-18:00 the next day",

	已绑定:"Vinculado",
	去设置:"Configuración",
	汇率:"Tipo de cambio",
	请输入正确的提现金额:"Ingrese el monto de retiro correcto",
	提款记录:"Registro de retiro",
	"绑定的钱包地址必须跟付款钱包一致，否则不会到账。":"tip1:La dirección de la billetera enlazada debe ser consistente con la billetera de pago, de lo contrario no se recibirá。",
	"充值成功后预计在一分钟左右到账，请检查钱包约。":"tip2:Se espera que llegue aproximadamente un minuto después de la recarga exitosa. Por favor revise su billetera.",
	请先绑定钱包地址:"Vincule primero la dirección de la billetera",
	输入邮箱:"Ingresa a Gmail",
	指定用户不存在:"El usuario especificado no existe",
	今日涨幅:"Incrementar",
	快捷充币:"Pagos y depósitos en línea",
	快速买USDT:"Empieza a ganar dinero",
	在线支付:"Pago en línea",
	自动到账:"Recibo automático",
	线下支付:"Pago fuera de línea",
	联系客服获取验证码:"Póngase en contacto con el servicio de atención al cliente para obtener el código de verificación",
	获取:"Obtener",
	重新登录:"Ingresar de nuevo",
	退出成功:"Salir con éxito",
	不能低于最小充值:"No puede ser inferior a la recarga mínima",
	不能低于最小提现金额:"No menos que el monto mínimo de retiro",
	最小提现:"Retiro mínimo",
	官方充币:"Recarga Oficial",
	银行卡充值:"Recarga de tarjeta bancaria",
	等待审核:"Revisión pendiente",
	可提现金额不足:"Cantidad de retiro insuficiente",
	未经授权:"no acreditado",
	交易密码不正确:"Contraseña de transacción incorrecta",
	提现次数不足:"Tiempos de retiro insuficientes",
	推荐满: "推荐满",
	人可购买: "人可购买",
	需要更新的银行卡不存在:"La tarjeta bancaria a actualizar no existe",
	银行卡提现:"Retiro de tarjeta bancaria",
	激活金额:"Activación gratuita",
	'赠送/出售':"Dar / Venta",
	赠送:"Dar",
	出售:"Venta",
	账户usdt不足:"Falta de balance",
	请输入提现金额:"Monto del retiro",
	恭喜新用户:"Felicidades a los nuevos usuarios ",
	kefu1:"Comunicación en línea con el cliente",
	登录密码:"Contraseña de inicio de sesión",
	再次请输入交易密码:"Ingrese la contraseña de la transacción nuevamente",
	输入手机号码:"Número de teléfono",
	输入手机号码:"Ingresa número telefónico",
	输入密码:"Contraseña",
	请输入密码:"Introducir la contraseña",
	手机号格式错误:"Error de formato de número de móvil",
	"密码错误次数过多,请等待x秒后再试":"Demasiados errores de contraseña, espere 600 segundos y vuelva a intentarlo",
	"此账号已冻结,请联系客服":"Esta cuenta ha sido congelada, comuníquese con el servicio al cliente",
	登录失败:"Error de inicio de sesion",
	请勿重复操作:"No repitas",
	邀请码不正确:"Código de invitación incorrecto",
	此账号已冻结:"Esta cuenta ha sido congelada",
	请输入手机号:"Por favor, introduzca su número de móvil",
	验证码错误:"Error de código de verificación",
	手机号存已存在:"El número de teléfono móvil ya existe",
	注册账号已存在:"La cuenta registrada ya existe",
	请确定新密码:"Por favor, confirme la nueva contraseña.",
	请再次输入登录密码:"Por favor, introduzca su contraseña de inicio de sesión de nuevo",
	密码长度不能少于6位:"La longitud de la contraseña no puede ser inferior a 6 dígitos",

	输入邮箱:"Ingresa a Gmail",
	注册邮箱已存在:"Registro Gmail ya existe",
	请输入用户ID:"Ingrese el GMAIL de la otra parte",
	"输入对方账户名称，转入财产请仔细审核信息，如操作失误，无法通过系统或平台客服找回。":
	"Revise cuidadosamente la información si ingresa el nombre de la cuenta de la otra parte y transfiere la propiedad. Si hay un error de operación, no se puede recuperar a través del sistema o el servicio de atención al cliente de la plataforma..",
	请输入出售金额:"Por favor ingrese el monto de la venta",
	出售中:"El regalo ha sido enviado, esperando confirmación",
	 收到一台系统赠送的机器人 :"Recibió un robot del sistema",
	 会员出售:"Los miembros venden",
	 "出售待确认中,请勿重复操作":"Venta pendiente de confirmar, no repetir operación",
	 激活成功:"Activa el éxito",
	 操作成功:"La operación es exitosa",

	输入邮箱:"Ingresa a Gmail",
购买需要直推: "购买需要直推",
	人: "人",
	请输入登录密码:"Por favor, introduzca su contraseña de inicio de sesión",
	未满足条件:"Condición no cumplida",
	活动已过期:"Actividad caducada",
	"邀请好友注册，领取佣金":"B:Invita a tus amigos a activar bots para obtener bonificaciones",
	领取:"Recibir",
	团队规则:"Regla del equipo",
	邮箱已存在:"El Gmail ya existe",
	获取邮箱验证码:"Obtener código",
	请输入邮箱:"Introduce tu correo GMAIL",
	手机号码:"Teléfono",
	邮箱不能为空:"El correo electrónico no puede estar vacío",
	"出售/赠送":"Venta / Regalo",
	已接受:"Aceptado",
	"出售/赠送人":"vendedor / donante",
	"向你赠送一台机器人，待确认":"Se le presenta un robot para su confirmación.",
	国码:"Código de país",
	取消:"Cancelar",
	银行卡号位数错误:"Número incorrecto de tarjetas bancarias",
	官网:"Sitio oficial",
	注册成功:"Iniciar sesión con éxito",
	设置成功:"Establecer con éxito",
	手机号码已存在:"El número de móvil ya existe",
	修改成功:"Modificado con éxito",
	立即登录:"Ya tienes una cuenta, haz clic para iniciar sesión",
	客服:"Charla",
	邮箱:"Email",
	机器人量化AI交易收益:"Robot cuantifica los ingresos comerciales de la IA",
	体验机器人不能赠送:"Los robots de experiencia no se pueden regalar",
	接受:"Aceptar",
	拒绝:"Rehusar",
	语言切换:"Interruptor de idioma",
	个人存款奖金活动: 'Campaña de bonificación de depósito personal',
	修改钱包: "To modify the USDT wallet for the link, please contact customer service to verify",
}
