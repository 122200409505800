<template>
  <layout :isShowFooter="true">
    <template #title>
	<div class="logg" style="color: #000;font-weight: bold;">{{ $t('活动中心') }}</div>
	</template>
   <template #right></template>
    <template #content>
	 
	   <!-- 	<div style="padding-left: 20px;color: #1a1a1a"> {{$t('个人存款奖金活动')}}</div> -->
	       <div class="item-Div" v-for="(item,index)  in yaoqinglist" v-bind:key="index">
	         <div class="item-div-left" style="">
	           <div style="width: 26px;height: 26px;border-radius: 18px;text-align: center;background-color: seagreen;">
	             <h2 style="color: white;margin-top: 0.1rem;">{{index +1}}</h2>
	           </div>
	         </div>
	         <div class="item-div-center">
	           <div style="display: flex">
	             <h3 style="color: white;">{{item.name}}</h3>
	           </div>
			   <div>
			     <h3 style="color: red;margin-top: 0px;">+{{item.jiangli}} U</h3>
			   </div>
	           <h4 class="item-div-center-tips" style="width: 90%;">{{item.desc}}</h4>
	
	         </div>
	         <div class="item-div-right" >
	           <div style="display: flex;text-align: center;margin-left: 55px;">
	             <div style="color: #07c160;font-size: 14px;display: flex">{{item.yi}}<div style="color: wheat"> / {{item.need_count}}</div></div>
	           </div>
	           <!-- <div v-if="item.lq_st==0" @click="lingqu(item.id)" style="width: 70px;height: 25px;background-color: crimson;border-radius: 15px;margin-top: 50px;display: inline-block;text-align: center">
	               <div style="color: white;font-size: 14px;margin-top: 3px">{{$t('领取')}}</div>

	  		   </div> -->
			   
			  <div v-if="item.lq_st==0" style="width: 100%; height: 25px; background-color: rgb(7, 193, 96); border-radius: 5px; margin-top: 50px; display: inline-block; text-align: center; margin-left: -5px;">
			       <div style="color: white;font-size: 14px;margin-top: 3px">{{$t('联系客服')}}</div>
			   
			   </div>
	         </div>
	       </div>
		   <div style="padding-left: 20px;color: black;"> </div>
		      <div class="item-Div" v-for="(item,index)  in yaoqinglist2" v-bind:key="index">
		        <div class="item-div-left" style="">
		          <div style="width: 26px;height: 26px;border-radius: 18px;text-align: center;background-color: seagreen;">
		            <h2 style="color: white;margin-top: 0.1rem;">{{index +1}}</h2>
		          </div>
		        </div>
		        <div class="item-div-center">
		        <div style="display: flex">
		          <h3 style="color: white;">{{item.name}}</h3>
		        </div>
		        <div>
		          <h3 style="color:red;margin-top: 0px;">+{{item.jiangli}} U</h3>
		        </div>
		        <h4 class="item-div-center-tips"  style="width: 90%;">{{item.desc}}</h4>
		    
		        </div>
		        <div class="item-div-right" >
		          <div style="display: flex;text-align: center;margin-left: 55px;">
		            <div style="color: #07c160;font-size: 14px;display: flex">{{item.yi}}<div style="color: wheat"> / {{item.need_count}}</div></div>
		          </div>
		         <!-- <div v-if="item.lq_st==0" @click="lingqu(item.id)" style="width: 70px;height: 25px;background-color: crimson;border-radius: 15px;margin-top: 55px;display: inline-block;text-align: center">
		              <div style="color: white;font-size: 14px;margin-top: 3px">{{$t('领取')}}</div>

		   	   </div> -->
			  
			   <div v-if="item.lq_st==0" style="width: 100%; height: 25px; background-color: rgb(7, 193, 96); border-radius: 5px; margin-top: 50px; display: inline-block; text-align: center; margin-left: -5px;">
			          <div style="color: white;font-size: 14px;margin-top: 3px">{{$t('联系客服')}}</div>
			   
			   </div>
		        </div>
		      </div>
		   <ROBOT />
	   <div class="card card-1 flexbox_column   ">
	  <div class="flexbox_column a-center" style="width: 100%;">

	    <img class="typeicon" :src="info.huodong_img" alt="" />

	  </div>
	  </div>
    </template>
  </layout>
</template>

<script>
	import ROBOT from '@/components/robot'
	import {
	  apikongtou, apiky_buy,apiUser_mas2,

	} from "@/http/api/";
	import {
	  NavBar,
	  Button,
	  DropdownMenu,
	  DropdownItem,
	  Tab,
	  Tabs,
	  Popup,
	  Field,
	  Slider,
	  Toast,
	} from "vant";
export default {
	components: {

		 ROBOT
	},
  data() {
    return {
		yaoqinglist:[],
		yaoqinglist2:[],
		token:"",
		info:[],
      tab1: {
        title: [
          'Robot\ntype',
          'Robot price',
          'Start-up funds',
          'Total amount',
          'Daily income'
        ],
        data: [
          {
            i: 'NO1',
            price: '$2',
            startUp: '$20',
            total: '$22',
            daily: '$0.3-0.4'
          },
          {
            i: 'NO2',
            price: '$5',
            startUp: '$100',
            total: '$105',
            daily: '$1.5-1.8'
          }
        ]
      },
      tab2: {
        title: [
          'Points \nAccumula \ntion',
          'Agent \n Level',
          'Subodinates\n Consumer\n Rebate',
          'Discount\n For Rob ot \nPurchase'
        ],
        data: [
          {
            i: 'NO1',
            price: '$2',
            startUp: '$20',
            total: '$22'
          },
          {
            i: 'NO2',
            price: '$5',
            startUp: '$100',
            total: '$105'
          }
        ]
      },
      tab3: {
        title: [
          'Agent\nLevel',
          'Agent\n Reward\n Ratio(%)',
          'Subordinate\n Daily \nProfit($)',
          'Agent\n Reward\n ($)'
        ],
        data: [
          {
            i: 'NO1',
            price: '$2',
            startUp: '$30',
            total: '$32'
          },
          {
            i: 'NO1',
            price: '$2',
            startUp: '$30',
            total: '$32'
          }
        ]
      },
      tab4: {
        title: [
          'Agent\nLevel',
          'Reference0 \nbject',
          'A>30% of\n B/C/D',
          '20% of B/ \n C/D < A≤3 \n 0% of B/C/D',
          'A≤20% of \n B/C/D'
        ],
        data: [
          {
            i: 'NO1',
            price: '$2',
            startUp: '$30',
            total: '$32',
            totals: '$32'
          },
          {
            i: 'NO1',
            price: '$2',
            startUp: '$30',
            total: '$32',
            totals: '$32'
          }
        ]
      }
    }
  },
  created() {
     var token = localStorage.getItem("key");
     console.log("token", token);
     if (!token) {
       this.$router.replace("/login");
     }
     this.token = token;
     this.init();
	 apiUser_mas2({
	   userid: token
	 }).then((res) => {
	   // 未登录踢下线
	   if (res.status == 10) {
	     this.$router.replace('/login')
	   }
	   // this.showdown = res.user.showdown
	   this.info = res.user

	 })
   },
  methods: {
	  async lingqu(id){
	  	await apiky_buy({
	  	  userid: this.token,
	  	  id:id
	  	}).then((res) => {
	  		if(res.status == 0){
				setTimeout(() => {
	  			Toast.fail(this.$t(res.info));
				window.location.reload();
				}, 1000);
	  		}else{
	  			setTimeout(() => {
	  			  Toast.success(this.$t(res.info));
	  			  // this.init();
				   window.location.reload();
	  			}, 1000);
	  		}
	  	});
	  },
	  init(){

	  		  this.yaoqinglist = [];
	  		  apikongtou({
	  		    userid: this.token,
	  		  }).then((res) => {
				for(var i =0; i < res.info.length; i++ ){
					if(res.info[i].type == 1){
						this.yaoqinglist.push(res.info[i])
					}else if(res.info[i].type == 2){
						this.yaoqinglist2.push(res.info[i])
					}

				}

	  		});
	  },
  }
}
</script>

<style lang="scss" scoped>
.content {
	.item-Div{
	  width: 95%;
	  height: 240px;
	  background:#000;
	  border-radius: 10px;
	  display: flex;
	  margin-left: 10px;
	  margin-top: 10px;
	}
	.item-div-right{
	  width: 300px;
	  height: 100px;
	  display:table-cell;
	  text-align: left;
	  vertical-align: middle;
	  padding-top: 20px;
	}
	.item-div-center{
	  width: 60%;
	  height: 100px;
	  text-align: left;
	  margin-top: 15px;
	}
	.item-div-center-tips{
	  color: #e7e7e7;
	  margin-top: 5px;
	  font-size: 18px
	}
	.item-div-left{
	  width: 15%;
	  height: 100px;
	  display: flex;
	  justify-content: center;
	  margin-top: 80px;
	}
  .card {
    background: #c8c9cc00;
    border-radius: 30px 30px 30px 30px;
    opacity: 1;
    padding: 35px;
    .typeicon {
      width: 100%;
    }

    .table {
      border-radius: 13px 13px 13px 13px;
      overflow: hidden;
      .title {
        background: linear-gradient(
          180deg,
          #0d9135 0%,
          rgba(243, 204, 46, 0) 100%
        );
        .title-item {
          flex: 1;
          min-height: 90px;
          display: flex;
          justify-content: center;
          align-items: center;
          white-space: pre-wrap;
          text-align: center;
        }
      }
      .body-item {
        height: 77px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
  .card-1 {
    .robot {
      width: 128px;
      height: 128px;
    }
  }
}
</style>
