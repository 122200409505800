<template>
    <div class="page">
        </br>
        <div class="header">
            <div @click="getBack" style="padding-top: 6px;">
                <img class="img" src="../../assets/img/arrow.png" style="width: 25px;height: 25px;" alt=""/>
            </div>
            <div class="logg" style="color: #000;font-weight: bold;">{{ $t('个人收入记录') }}</div>
            <div></div>
        </div>
        <div class=" mt24 ml32 mr32" style="height: 0; opacity: 2; border: 0.01px solid #4CAF50;"></div>
        <div class="choose" style="border-radius:5px 5px 5px 5px;">
            <van-tabs v-model="active" :ellipsis="false" style="background: #000;border-radius:5px 5px 5px 5px;">
                <van-tab :title="$t('个人收益累计')" name="1">
          
                        <van-list v-model="loading" :finished="finished" :finished-text="$t('暂无数据')" @load="onLoad"
                                  style="border-radius:5px 5px 5px 5px;">
                            <van-cell v-for="item in list" :key="item.id" style="border-radius:5px 5px 5px 5px;">
                                <div class="learn">
                                    <div class="box">
                                        <div>
                                            <div class="natixis robotl">
                                                <!-- <div>{{$t('时间')}} </div> -->
                                                <span class="tags"></span>
                                            </div>
                                            <div>
                                                <span>{{ item.create_time }}</span>
                                            </div>
                                        </div>
                                        <div>
                                            <div class="price">{{ item.amount }}<span style='font-size: 12px'>USDT</span></div>
                                            <!-- <div>{{ $t(item.memo) }}</div> -->
                                        </div>
                                    </div>
                                </div>
                            </van-cell>
                        </van-list>
              
					
                </van-tab>
                <van-tab :title="$t('代理累计收益')" name="2">
                  
                        <van-list v-model="loading" :finished="finished" :finished-text="$t('暂无数据')" @load="onLoad" style="border-radius:5px 5px 5px 5px;">
                            <van-cell v-for="item in list" :key="item.id" style="border-radius:5px 5px 5px 5px;">
                                <div class="learn">
                                    <div class="box">
                                        <div>
                                            <div class="natixis robotl">
                                                <div>{{ $t('时间') }}</div>
                                                <span class="tags"></span>
                                            </div>
                                            <div>
                                                <span>{{ item.create_time }}</span>
                                            </div>
                                        </div>
                                        <div>
                                            <div class="price">{{ item.amount }}<span style='font-size: 12px'>USDT</span></div>
                                            <div>{{ $t(item.memo) }}</div>
                                        </div>
                                    </div>
                                </div>
                            </van-cell>
                        </van-list>
                    
					</br></br></br>
                </van-tab>


                <van-tab :title="$t('奖金个人收益')" name="4">
                 
                        <van-list v-model="loading" :finished="finished" @load="onLoad" :finished-text="$t('暂无数据')" style="border-radius:5px 5px 5px 5px;">
                            <van-cell v-for="item in list" :key="item.id" style="border-radius:5px 5px 5px 5px;">
                                <div class="learn">
                                    <div class="box">
                                        <div>{{ $t('状态') }}</div>
                                        <div class="kna-status" v-if="item.status == 0">
                                            <div> {{ $t('待审核') }}</div>
                                        </div>
                                        <div class="kna-status" style="color: green;" v-if="item.status == 1">
                                            <div>{{ $t('成功') }}</div>
                                        </div>
                                        <div class="kna-status" style="color:red;" v-if="item.status == 2">
                                            <div>{{ $t('失败') }}</div>
                                        </div>
                                        <div class="kna-status" style="color:red;" v-if="item.status == 3">
                                            <div>{{ $t('审核中') }}</div>
                                        </div>
                                    </div>
                                    <div class="box">
                                        <div>{{ $t('数量') }}:</div>
                                        <div style="font-size: 20px; color: #fff">{{ item.amount }}USDT</div>
                                    </div>
                               <!--     <div class="box">
                                        <div>{{ $t('交易编号') }}:</div>
                                        <div style="font-size: 8px;">{{ item.id }}</div>
                                    </div> -->
                                    <!-- <div class="box">
                                        <div>{{ $t('描叙') }}:</div>
                                        <div>{{ $t(item.memo) }}</div>
                                    </div> -->
                                    <div class="box">
                                        <div>{{ $t('备注') }}</div>
                                        <div v-if="item.bz !== null">
                                            <div> {{ item.bz }}</div>
                                        </div>
                                        <div v-if="item.bz == null">
                                            <div> {{ item.bz }}</div>
                                            {{ $t('暂无备注') }}
                                        </div>
                                    </div>

                                    <div class="box">
                                        <div>{{ $t('时间') }}</div>
                                        <div style="font-size: 14px;">
                                            <div> {{ item.create_time }}</div>
                                        </div>
                                    </div>
                                </div>
                            </van-cell>
                        </van-list>
                    
					</br></br></br>
                </van-tab>

            </van-tabs>
        </div>


        <div class="footer">
            <van-tabbar v-model="active" route>
                <van-tabbar-item
                        v-for="item in tabbar"
                        :key="item.title"
                        :to="item.url"
                >
                    <span>{{ item.title }}</span>
                    <template #icon="props">
                        <img :src="props.active ? item.iconA : item.icon" alt=""/>
                    </template>
                </van-tabbar-item>
            </van-tabbar>
        </div>


        <ROBOT></ROBOT>
    </div>
</template>

<script>
import ROBOT from '@/components/robot'
import {apishourujilu, getUrlKey} from "@/http/api/";
import {NavBar, Tab, Tabs, Empty, PullRefresh, Toast, List} from "vant";

export default {
    name: 'welcome',
    components: {
        ROBOT
    },
    data() {
        return {
            active: '2',
            list: [],
            loading: false,
            finished: false,
            token: "",
            type: 1,
            page: 1,
        }
    },
    watch: {
        active(val) {
            this.list = []
            this.page = 1;

            this.loading = false
            this.finished = false
            this.onLoad()
        }
    },
    created() {
        var token = localStorage.getItem("key");
        console.log("token", token);
        if (!token) {
            this.$router.replace("/login");
        }
        this.token = token;
        var url = window.location.href;
        var index = getUrlKey('active', url);
        if (index) {
            this.active = getUrlKey('active', url);
        }
    },


    computed: {
        tabbar() {
            return [
                {
                    title: this.$i18n.t('tabbar.首页'),
                    icon: require('@/assets/tabbar/1.png'),
                    iconA: require('@/assets/tabbar/1-1.png'),
                    url: '/home'
                },
                {
                    title: this.$i18n.t('tabbar.市场'),
                    icon: require('@/assets/tabbar/2.png'),
                    iconA: require('@/assets/tabbar/2-2.png'),
                    url: '/market'
                },

                {
                    title: this.$i18n.t('tabbar.团队'),
                    icon: require('@/assets/tabbar/4.png'),
                    iconA: require('@/assets/tabbar/4-4.png'),
                    url: '/team'
                },
                {
                    title: this.$i18n.t('tabbar.我的'),
                    icon: require('@/assets/tabbar/5.png'),
                    iconA: require('@/assets/tabbar/5-5.png'),
                    url: '/my'
                },
                // {
                //   title: this.$i18n.t('客服'),
                //   icon: require('@/assets/tabbar/3.png'),
                //   iconA: require('@/assets/tabbar/3-3.png'),
                //   url: '/my'
                // }
            ]
        }
    },

    methods: {


        formatTimestamp(timestamp) {
            // 创建一个Date对象，传入时间戳（秒级别）
            const date = new Date(timestamp * 1000); // 将时间戳转换为毫秒级别
            // 使用 Date 对象的方法获取年月日时分秒
            const year = date.getFullYear();
            const month = ('0' + (date.getMonth() + 1)).slice(-2); // 月份从0开始，所以要加1，并且确保两位数字
            const day = ('0' + date.getDate()).slice(-2); // 日期确保两位数字
            const hours = ('0' + date.getHours()).slice(-2); // 小时确保两位数字
            const minutes = ('0' + date.getMinutes()).slice(-2); // 分钟确保两位数字
            const seconds = ('0' + date.getSeconds()).slice(-2); // 秒确保两位数字
            // 拼接成 YYYY-MM-DD HH:mm:ss 格式的字符串
            return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
        },


        copyEvent() {
            console.log('fuzhi ')
        },
        getBack() {
            this.$router.back()
        },
        onLoad() {
            Toast.loading({
                duration: 0,
                message: this.$t('加载中'),
                forbidClick: true,
            });
            var that = this;
            apishourujilu({
                userid: this.token,
                page: this.page,
                type: this.active
            }).then((res) => {
                Toast.clear();
                for (let i = 0; i < res.info.length; i++) {
                    that.list.push(res.info[i]);
                }
                // 数据全部加载完成
                if (res.info.length == 0) {
                    that.finished = true
                }
            });
            this.page++
            // 加载状态结束
            this.loading = false
        }
    }
}
</script>

<style lang="scss" scoped>


.page {
  //   padding: 0 30px;
  .header {
    padding: 0 40px;
    height: 56px;
    line-height: 56px;
    display: flex;
    justify-content: space-between;

    .close {
      width: 28px;
      height: 28px;
    }

    .img {
      width: 40px;
    }

    .logg {
      font-size: 40px;
      font-family: PingFang SC-中粗体, PingFang SC;
      font-weight: normal;
      color: #ffffff;
    }
  }

  .choose {
    margin-top: 20px;
    padding: 0 24px;

    .learn {
      margin-top: 30px;
      color: #fff;
      padding: 20px 25px;
      border-bottom: 1px solid #2a2c2e;

      .box {
        color: #888;
        // margin-bottom: 10px;
        align-items: center;
        display: flex;
        justify-content: space-between;

        .natixis {
          display: flex;
          align-items: center;
          margin-bottom: 20px;

          .img {
            margin-right: 10px;
            width: 60px;
            height: 60px;
          }
        }

        .price {
          font-size: 36px;
          color: #fff;
        }

        .robotl {
          font-size: 24px;
          color: #fff;

          .tags {
            margin-left: 10px;
          }
        }
      }
    }
  }

  .pitera {
    color: #000;
    line-height: 60px;
    font-size: 24px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
  }

  .footer {
    position: fixed;
    font-size: 24px;
    color: #888;
    bottom: 0;
    height: 90px;
    width: 100%;
    text-align: center;
    line-height: 90px;
    // background-color: #2a2c2e;
    border-radius: 30px 30px 0 0;

    .num {
      color: #0d9135;
      font-size: 30px;
      margin-right: 30px;
    }

    .comeTo {
      margin-right: 20px;
    }

    .addUpTo {
      font-size: 30px;
      color: #fff;
    }
  }
}
</style>
