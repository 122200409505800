<template>
  <layout>
	<template #left>
	<span style="display: flex; align-items: center; justify-content: center; margin-left: 0.26667rem;">
	  <img style="width: 20px; height: 20px; margin-right: 10px;text-align: left;" src="../../assets/tabbar/2-2.png" alt="" />
	
	</span>
	</template>
	<template #title>
	  <span style="display: flex; align-items: center; justify-content: center;">
	
	    <span style="color: #000;text-align: center;font-weight: bold;">{{ $t('market.市场') }}</span>
	  </span>	
	</template>
   

    <template #content>
      <div class="content" style="margin-top: -35px;">
        
        <div class="list-bg">

          <van-row class="ml32 mr32 mt20 listTitle flexbox a-center" 
style="color: rgb(255, 255, 255); background-color: #16c784 !important; padding-right: 8px; padding-left: 8px; height: 38px; font-size: 12px; line-height: 25px; border-radius: 3px; font-weight: bold; box-shadow: #16c78482 0px 0px 10px 1px;">
            <van-col span="10">{{ $t('market.名称') }}</van-col>
            <van-col class="flexbox a-center j-endl cl" span="14">
              <div>{{ $t('market.价格') }}</div>
              <div class=" sanjiao ml5 ">
                <div class="up"></div>
                <div style="height: 1px"></div>
                <div class="down"></div>
              </div>
            </van-col>
            <van-col class="flexbox a-center j-endl" span="6">
              <div>{{ $t('今日涨幅') }}</div>
              <div class="sanjiao ml5">
                <div class="up"></div>
                <div style="height: 1px"></div>
                <div class="down"></div> 
              </div>
            </van-col>
          </van-row>
         
          <van-row
            class="ml32 mr32 mt20 list-item flexbox a-center tl" style="color: rgb(255, 255, 255); background-color: #222531 !important; box-shadow:#2225317a 0px 0px 10px 1px; padding-right: 8px; padding-left: 8px; height: 23px; font-weight: 600; font-size: 12px; line-height: 25px; border-radius: 3px;"
            v-for="(item, i) in HQlist"
            :key="i"
          >
            <van-col span="10" class="f32">{{item.name}}/<span style="color: #fff;">USDT</span></van-col>
            <van-col class="flexbox a-center j-endl" span="10">
              <div
                class="f32"
                :style="{ color: item.isUp ? '#ff0020' : '#16c784' }"
              >
               {{Number(item.last).toFixed(2)}}
              </div>
            </van-col>
            <van-col class="flexbox a-center j-endl" span="8">
              <!-- <van-button :color="item.isUp ? '#FF5E73' : '#2EB98B'">{{
                item.percent
              }}</van-button> -->
              <div
                class="btn flexbox center"
                :style="{ background: item.isUp ? '#ea3943' : '#16c784' }"
              >
                {{ item.change }}%
              </div>
            </van-col>
          </van-row>
          <div class="mt10 ml32 mr32 robot flex_row_space-between">
            <div class="ml32 flexbox">
              <img
                class="robot-icon"
                :src="require('@/assets/icon/logo-2-w.png')"
                alt=""
              />
         <!--    <div class="ml20 bcolor">
                <div>
                  <span class="f40">{{zuodan.name}}</span>
                  <span class="ml5">USDT:{{zuodan.danprice}}</span>
                </div>
                <div class="mt5 f18 flexbox center">
                  <span></span>{{zuodan.day1}} / {{zuodan.day2}} {{ $t("天") }}</span>
                  <div class="ml10 mr10 dot"></div>
                  <div><span class="t">{{ $t("状态") }}：</span>{{ $t("正常") }}</div>
                </div>
              </div> -->
            </div>
            <div>
              <div class="lines"></div>
              <div class="mr32 bcolor flexbox_column a-center">
                <div class="f34 bold" style="color: #ffeb3b;">{{myaicount}}</div>
                <div class="f28 mt10" style="color: #fff;">{{ $t("我的机器人") }} </div>
              </div>
            </div>
          </div>
          <div
            class="ml32 mr32 mt30 list-qushi flex_row_space-between a-center"
			style="color: rgb(255, 255, 255); background-color: #009688 !important; box-shadow: #009688 0px 0px 10px 1px; padding-right: 8px; padding-left: 8px; height: 63px; font-weight: 600; font-size: 12px; line-height: 25px; border-radius: 3px;"
            v-for="item in list"
            :key="item.title"
          >
            <div class="flexbox a-center">
              <div class="img">
				  <img class="img" :src='item.img'/>
			  </div>
              <div class="title ml10">
                <div class="f32">{{item.name}}</div>
                <div class="mt5">{{item.add_time}}</div>
              </div>
            </div>
            <div class="flexbox">
              <img class="img-updown" v-show="item.isup == 1" src="@/assets/icon/mup.png"/>
			  <img class="img-updown" v-show="item.isup == -1"  src="@/assets/icon/mdown.png"/>
			  
              <div class="ml20">
                <div></div>
                <div class="flexbox a-center" style="font-size: 14px;">
                 <!-- <img class="qushi-icon" v-show="item.isup == 1" src="assets/icon/mup.png"/>
				  <img class="qushi-icon" v-show="item.isup == -1"  src="assets/icon/mdown.png"/> -->
				  
                  <div>{{ item.amount }} USDT</div>
                </div>
              </div>
            </div>
          </div>
        </div>
		<ROBOT />
      </div>
	
    </template>
	  
  </layout>
</template>

<script>
	import ROBOT from '@/components/robot'
import { mapGetters } from 'vuex'
import { apiHangqing,apiRobots,apiUser_mas2,apigetearnings,apizuodan,apiactionai ,apimyrobots} from "@/http/api/";
import { Toast } from 'vant'
import pako from 'pako'
export default {
	components: {
	  
		ROBOT,
	
	},
  data() {
    return {
      partnerStatus: false,
      priceStatus: true,
      changeStatus: true,
		btc:'',
		fil:'',
		xch: '',
		doge: '',
		bch: '',
		zec: '',
		eth: '',
		eos: '',
		ltc: '',
		etc: '',
		token:"",
		aituring:[],
		zuodan:[],
		myaicount:"",
		list:[],
		HQlist:[]
    }
  },
  computed: {
    ...mapGetters(['partnerList'])
  },
	created() {
	  var token = localStorage.getItem("key");    
	  if (!token) {
		this.$router.replace("/login");
	  }
	  this.token = token;
	  apizuodan({userid: token}).then((res) => {
		if(res.status == 10){
			this.$router.replace("/login");
		}
		this.myaicount = res.info;
	   }); 
	   apiactionai({userid: token}).then((res) => {	
			if(res.info){
				this.zuodan = res.info;
			}	
		}); 
		this.init();
		this.getHQ();
	},
	methods:{
		init(){
			Toast.loading({
			  duration: 0,
			  message: this.$t('加载中'),
			  forbidClick: true,
			  
			});
		  apigetearnings({
		  userid: this.token,
		  page:this.page
		  }).then((res) => {
		 Toast.clear();
		  for(let i = 0; i < res.info.length; i++){
				let iarr = res.info[i];
				if(iarr.amount < 0){
					res.info[i].isup = -1;
				}else{
					res.info[i].isup = 1;
				}
				console.log(res.info[i]);
			  this.list.push(res.info[i]);
		  }
		  
		  });
		},
		getHQ(){
			apiHangqing({}).then((res) => {
				this.HQlist = res
				this.btc = res[0].last;
				this.fil = res[1].last;
				this.xch = res[2].last;
				this.doge = res[3].last;
				this.bch = res[4].last;
				this.zec = res[5].last;
				this.eth = res[6].last;
				this.eos = res[7].last;
				this.ltc = res[8].last;
				this.etc = res[9].last;
				this.sendSocket()
			}); 
		},
		// 发送socket
		sendSocket() {
			let t = this
			this.socket = new WebSocket('wss://api-aws.huobi.pro/ws')
			this.socket.onopen = this.open
			// 监听socket消息
			this.socket.onmessage = this.getMessage
		},
		open: function() {
			// console.log("socket连接成功");
			let arr = ['btcusdt', 'filusdt', 'xchusdt', 'dogeusdt', 'bchusdt', 'zecusdt', 'ethusdt', 'eosusdt', 'ltcusdt', 'etcusdt'];
			var _that = this.socket
			arr.forEach(function(item, index) {
				let subK = { // 订阅数据
					sub: "market." + item + ".kline.1min",
					id: item
				};
				_that.send(JSON.stringify(subK));
			});
		 
		},
		getMessage: function(event) {
			let blob = event.data;
			
			let reader = new FileReader();
			var _that = this;
			reader.onload = function(e) {
				let ploydata = new Uint8Array(e.target.result);
				let msg = pako.inflate(ploydata, {
					to: 'string'
				});
				msg = JSON.parse(msg);
				 //console.log(msg);
				// _that.lists = [];
				let name = '';
				let fu = '';
				let bili = '0.00';
				let color = '';
				let home = '';
				let zhi = '';
				let isUp = false;
				if (msg.ch) {
		
					if (msg.ch == 'market.btcusdt.kline.1min') {
						name = 'BTC';
		
						// 计算比例
						if (msg.tick.close >= _that.btc) {
							fu = '+';
							bili = ((msg.tick.close - _that.btc) / _that.btc) * 100;
							bili = Math.floor(bili * 100) / 100;
							isUp = false;
						} else {
							fu = '-';
							bili = ((_that.btc - msg.tick.close) / _that.btc) * 100;
							bili = Math.floor(bili * 100) / 100;
							isUp = true;
						}
		
						// _that.lists[0] = {'id':1,'name': name,'val':msg.tick.vol,'change': fu+bili,'cny': 0, 'color': color, 'home': home, 'zhi': zhi};
		
						_that.HQlist[0].name = name;
						_that.HQlist[0].val = msg.tick.vol;
						_that.HQlist[0].change = fu + bili;
						_that.HQlist[0].isUp = isUp;
						_that.HQlist[0].last = msg.tick.close;
					}
					if (msg.ch == 'market.filusdt.kline.1min') {
						name = 'FIL';					
						// 计算比例
						if (msg.tick.close >= _that.fil) {
							fu = '+';
							bili = ((msg.tick.close - _that.fil) / _that.fil) * 100;
							bili = Math.floor(bili * 100) / 100;
							isUp = false;
						} else {
							fu = '-';
							bili = ((_that.fil - msg.tick.close) / _that.fil) * 100;
							bili = Math.floor(bili * 100) / 100;
							isUp = true;
						}
						// _that.lists[1] = {'id':2,'name': name,'val':msg.tick.vol,'change': fu+bili,'cny': 0, 'color': color, 'home': home, 'zhi': zhi};
						_that.HQlist[1].name = name;
						_that.HQlist[1].val = msg.tick.vol;
						_that.HQlist[1].change = fu + bili;
						_that.HQlist[1].last = msg.tick.close;
						_that.HQlist[1].isUp = isUp;
					}
					if (msg.ch == 'market.xchusdt.kline.1min') {
						name = 'XCH';
						// 计算比例
						if (msg.tick.close >= _that.xch) {
							fu = '+';
							bili = ((msg.tick.close - _that.xch) / _that.xch) * 100;
							bili = Math.floor(bili * 100) / 100;
							isUp = false;
						} else {
							fu = '-';
							bili = ((_that.xch - msg.tick.close) / _that.xch) * 100;
							bili = Math.floor(bili * 100) / 100;
							isUp = true;
						}
						// _that.lists[2] = {'id':3,'name': name,'val':msg.tick.vol,'change': fu+bili,'cny': 0, 'color': color, 'home': home, 'zhi': zhi};
						_that.HQlist[2].name = name;
						_that.HQlist[2].val = msg.tick.vol;
						_that.HQlist[2].change = fu + bili;
						_that.HQlist[2].isUp = isUp;
						_that.HQlist[2].last = msg.tick.close;
					}
					if (msg.ch == 'market.dogeusdt.kline.1min') {
						name = 'DOGE';
						// 计算比例
						if (msg.tick.close >= _that.doge) {
							fu = '+';
							bili = ((msg.tick.close - _that.doge) / _that.doge) * 100;
							bili = Math.floor(bili * 100) / 100;
							isUp = false;
						} else {
							fu = '-';
							bili = ((_that.doge - msg.tick.close) / _that.doge) * 100;
							bili = Math.floor(bili * 100) / 100;
							isUp = true;
						}
						// _that.lists[2] = {'id':3,'name': name,'val':msg.tick.vol,'change': fu+bili,'cny': 0, 'color': color, 'home': home, 'zhi': zhi};
						_that.HQlist[3].name = name;
						_that.HQlist[3].val = msg.tick.vol;
						_that.HQlist[3].change = fu + bili;
						_that.HQlist[3].isUp = isUp;
						_that.HQlist[3].last = msg.tick.close;
					}
					if (msg.ch == 'market.bchusdt.kline.1min') {
						name = 'BCH';
						// 计算比例
						if (msg.tick.close >= _that.bch) {
							fu = '+';
							bili = ((msg.tick.close - _that.bch) / _that.bch) * 100;
							bili = Math.floor(bili * 100) / 100;
							isUp = false;
						} else {
							fu = '-';
							bili = ((_that.bch - msg.tick.close) / _that.bch) * 100;
							bili = Math.floor(bili * 100) / 100;
							isUp = true;
						}
						// _that.lists[2] = {'id':3,'name': name,'val':msg.tick.vol,'change': fu+bili,'cny': 0, 'color': color, 'home': home, 'zhi': zhi};
						_that.HQlist[4].name = name;
						_that.HQlist[4].val = msg.tick.vol;
						_that.HQlist[4].change = fu + bili;
						_that.HQlist[4].isUp = isUp;
						_that.HQlist[4].last = msg.tick.close;
					}
					if (msg.ch == 'market.zecusdt.kline.1min') {
						name = 'ZEC';
						// 计算比例
						if (msg.tick.close >= _that.zec) {
							fu = '+';
							bili = ((msg.tick.close - _that.zec) / _that.zec) * 100;
							bili = Math.floor(bili * 100) / 100;
							isUp = false;
						} else {
							fu = '-';
							bili = ((_that.zec - msg.tick.close) / _that.zec) * 100;
							bili = Math.floor(bili * 100) / 100;
							isUp = true;
						}
						// _that.lists[2] = {'id':3,'name': name,'val':msg.tick.vol,'change': fu+bili,'cny': 0, 'color': color, 'home': home, 'zhi': zhi};
						_that.HQlist[5].name = name;
						_that.HQlist[5].val = msg.tick.vol;
						_that.HQlist[5].change = fu + bili;
						_that.HQlist[5].isUp = isUp;
						_that.HQlist[5].last = msg.tick.close;
					}
					if (msg.ch == 'market.ethusdt.kline.1min') {
						name = 'ETH';
						// 计算比例
						if (msg.tick.close >= _that.eth) {
							fu = '+';
							bili = ((msg.tick.close - _that.eth) / _that.eth) * 100;
							bili = Math.floor(bili * 100) / 100;
							isUp = false;
						} else {
							fu = '-';
							bili = ((_that.eth - msg.tick.close) / _that.eth) * 100;
							bili = Math.floor(bili * 100) / 100;
							isUp = true;
						}
						// _that.lists[2] = {'id':3,'name': name,'val':msg.tick.vol,'change': fu+bili,'cny': 0, 'color': color, 'home': home, 'zhi': zhi};
						_that.HQlist[6].name = name;
						_that.HQlist[6].val = msg.tick.vol;
						_that.HQlist[6].change = fu + bili;
						_that.HQlist[6].isUp = isUp;
						_that.HQlist[6].last = msg.tick.close;
					}
					if (msg.ch == 'market.eosusdt.kline.1min') {
						name = 'EOS';
						// 计算比例
						if (msg.tick.close >= _that.zec) {
							fu = '+';
							bili = ((msg.tick.close - _that.eos) / _that.eos) * 100;
							bili = Math.floor(bili * 100) / 100;
							isUp = false;
						} else {
							fu = '-';
							bili = ((_that.eos - msg.tick.close) / _that.eos) * 100;
							bili = Math.floor(bili * 100) / 100;
							isUp = true;
						}
						// _that.lists[2] = {'id':3,'name': name,'val':msg.tick.vol,'change': fu+bili,'cny': 0, 'color': color, 'home': home, 'zhi': zhi};
						_that.HQlist[7].name = name;
						_that.HQlist[7].val = msg.tick.vol;
						_that.HQlist[7].change = fu + bili;
						_that.HQlist[7].isUp = isUp;
						_that.HQlist[7].last = msg.tick.close;
					}
					if (msg.ch == 'market.ltcusdt.kline.1min') {
						name = 'LTC';
						// 计算比例
						if (msg.tick.close >= _that.zec) {
							fu = '+';
							bili = ((msg.tick.close - _that.ltc) / _that.ltc) * 100;
							bili = Math.floor(bili * 100) / 100;
							isUp = false;
						} else {
							fu = '-';
							bili = ((_that.ltc - msg.tick.close) / _that.ltc) * 100;
							bili = Math.floor(bili * 100) / 100;
							isUp = true;
						}
						// _that.lists[2] = {'id':3,'name': name,'val':msg.tick.vol,'change': fu+bili,'cny': 0, 'color': color, 'home': home, 'zhi': zhi};
						_that.HQlist[8].name = name;
						_that.HQlist[8].val = msg.tick.vol;
						_that.HQlist[8].change = fu + bili;
						_that.HQlist[8].isUp = isUp;
						_that.HQlist[8].last = msg.tick.close;
					}
					if (msg.ch == 'market.etcusdt.kline.1min') {
						name = 'ETC';
						// 计算比例
						if (msg.tick.close >= _that.etc) {
							fu = '+';
							bili = ((msg.tick.close - _that.etc) / _that.etc) * 100;
							bili = Math.floor(bili * 100) / 100;
							isUp = false;
						} else {
							fu = '-';
							bili = ((_that.etc - msg.tick.close) / _that.etc) * 100;
							bili = Math.floor(bili * 100) / 100;
							isUp = true;
						}
						// _that.lists[2] = {'id':3,'name': name,'val':msg.tick.vol,'change': fu+bili,'cny': 0, 'color': color, 'home': home, 'zhi': zhi};
						_that.HQlist[9].name = name;
						_that.HQlist[9].val = msg.tick.vol;
						_that.HQlist[9].change = fu + bili;
						_that.HQlist[9].isUp = isUp;
						_that.HQlist[9].last = msg.tick.close;
					}
				}
		
			};
			reader.readAsArrayBuffer(blob, "utf-8");
		
			var timea = Math.round(new Date() / 1000);
		
			// console.log(this.HQlist);
			this.$forceUpdate()
			var _thats = this.socket;
			setTimeout(function() {
		
				_thats.send(JSON.stringify({
					pong: timea
				}));
			}, 4000);
		
		},
		
		send: function() {
			var timea = Math.round(new Date() / 1000);
			var data = {
				'pong': timea
			};
			console.log(data);
			this.socket.send(data);
		},
		handleUrl(url) {
		  window.location.href = url
		},
		
	}
}
</script>

<style lang="scss" scoped>
	.custom-selector{
		@toast-background-color: #ffffff;
	}
.content {
  .height-auto {
    height: auto !important;
  }
  .jiantou-icon {
    width: 19px;
    height: 16px;
    margin-top: 15px;
  }
  .partner {
    height: 78px;
    overflow: hidden;

    .partner-item {
      width: 32%;
      margin-bottom: 20px;
      background-color: #2a2c2e;
      height: 58px;
      border-radius: 29px;
      margin-left: 3px;
      .imgIcon {
        width: 154px;
        height: 40px;
        max-width: 100%;
        max-height: 100%;
      }
    }
  }
  .list-bg {
    background:#00000000;
    border-radius: 10px 10px 10px 10px;
    opacity: 1;
    padding: 40px 0;
    margin-top: 30px;
	margin-left: 20px;
	margin-right: 20px;
    .listTitle {
      .sanjiao {
        .up {
          width: 0px;
          height: 0px;
          border: 10px solid;
          border-color: transparent;
          border-bottom-color: #d7d7d7;
        }
        .down {
          width: 0px;
          height: 0px;
          border: 10px solid; /* border的宽度决定了三角形的大小 */
          border-color: transparent; /* 设置border为透明 */
          border-top-color: #d7d7d7; /* 仅设置上方border显示 其他类似 */
        }
        .selectColor {
          color: #0d9135;
        }
      }
    }
  }
  .list-item {
    padding: 20px 0;
    .btn {
      width: 160px;
      height: 58px;
      border-radius: 8px 8px 8px 8px;
      opacity: 1;
    }
  }
  .robot {
    background: #1989fa;
	box-shadow: #1989fa 0px 0px 10px 1px;
    height: 100px;

    
    border-radius: 5px;
    opacity: 1;
    .dot {
      width: 10px;
      height: 10px;
      border-radius: 50%;
      border: 2px solid #fff;
      background: #202020;
    }
    .lines {
      width: 2px;
      height: 100%;
      background: #fff;
    }
    .robot-icon {
      width: 76px;
    }
  }
  .list-qushi {
    .img {
      width: 90px;
      height: 90px;
      background: #171718;
      opacity: 1;
      border-radius: 50%;
    }
    .img-updown {
      width: 30px;
      height: 30px;
	  margin-top: 5px;
    }
    .qushi-icon {
      width: 12px;
      height: 20px;
    }
  }
  .cl{
	justify-content: space-around;
  }
  .tl{
	  justify-content: space-between;
  }
}
</style>
