<template>
  <div class="page">
	</br>
	<div class="header">
	    <div @click="getBack">
	        <img class="img" src="../../assets/img/arrow.png" style="width: 25px;height: 25px;" alt=""/>
	    </div>
	    <div class="logg" style="color: #000;font-weight: bold;">{{ $t('邀请码') }}</div>
	    <div>
	    </div>
	</div>
	<div class=" mt24 ml32 mr32" style="height: 0; opacity: 2; border: 0.01px solid #4CAF50;"></div>
	
	
    <div class="contnet">
      <div class="bg">
        <!-- <img class="img" src="../../assets/img/建立自己的团队邀请.png" alt="" /> -->
        <div class="tx">{{ $t('建立自己的团队邀请') }}</div>
      </div>
      <div class="invitation" style="color: #000;">
        <div>
          <div >{{ $t('邀请码') }}</div>
          <div class="num">{{info.invit}}</div>
          <div class="clipboardBtnUrl join" @click="onCopy($t('复制成功'))" :data-clipboard-text="info.invit" style="text-align: center;">
           {{ $t("复制") }}
           
          </div>
        </div>
<!--       <div id="qrcode">
        </div> -->
		 
      </div>
      <div class="link">
      <p>{{$t('手机号邀请链接')}}</p>
        <p style="color: #0d9135; font-size: 14px;text-align: center;">
          {{yaourl}}   
		 <img :src="'https://api.pwmqr.com/qrcode/create/?url=' + yaourl" style="width: 200px; height: 200px;" alt="" />
        </p>
		</br></br></br></br></br>
		<div class="footerBtn" style="margin-top: -80px;">
		 <van-button  :data-clipboard-text="yaourl" class="clipboardBtnUrl affiliate"  @click="onCopy($t('复制成功'))" >
		    {{ $t("手机注册复制") }}
		 </van-button>
		</div>
		 <p>{{$t('邮箱邀请链接')}}</p>
		<p style="color: #0d9135; font-size: 14px;text-align: center;">
		  {{email}}
		   <img :src="'https://api.pwmqr.com/qrcode/create/?url=' + email" style="width: 200px; height: 200px;" alt="" />
		</p>
    
      </div>
    </div>
	
	<div class="footerBtn" style="margin-top: -80px;">
	 <van-button :data-clipboard-text="email" class="clipboardBtnUrl affiliate"  @click="onCopy($t('复制成功'))" >
		{{ $t("邮箱注册复制") }}
	 </van-button>
	</div>
	</br></br></br>
	
	
	
	<div class="footer" >
	  <van-tabbar v-model="active" route>
	    <van-tabbar-item
	      v-for="item in tabbar"
	      :key="item.title"
	      :to="item.url"
	    >
	      <span>{{ item.title }}</span>
	      <template #icon="props">
	        <img :src="props.active ? item.iconA : item.icon" alt="" />
	      </template>
	    </van-tabbar-item>
	  </van-tabbar>
	</div>
    <ROBOT />
    <!-- <div class="prompt">Cheqam在24小时内取款</div> -->
    
  </div>
</template>

<script>
import ROBOT from '@/components/robot'
import QRCode from "qrcodejs2-fix";
import { Button, Form, Field, Toast } from "vant";
import Clipboard from "clipboard";
import { apiUser_mas2 } from "@/http/api/";

export default {
  name: 'welcome',
  components: {
    ROBOT
  },
  data() {
    return {
      num: '',
      radio: 1,
      inputData: '',
      activeNames: ['1'],
      activeNum: ['0'],
	  info:[],
	  yaourl:'',
	  email:''
    }
  },
  created() {
	 const token = localStorage.getItem("key");
	 console.log("token", token);
	 if (token == null) {
	   this.$router.replace("/login");
	 } 
  	apiUser_mas2({
  	  userid: token,
  	}).then((res) => {
  	  console.log(res);
	  if (res.status == 10) {
	    this.$router.replace('/login')
	  }
  	  this.info = res.user;
	  this.yaourl = "https://lc2.ys202.com/#/register?code="+res.user.invit;
	  this.email = "https://lc2.ys202.com/#/registerByEmail?code="+res.user.invit;
	this.$nextTick(() => {
	   this.code()
	  })
  	});
	
  },
  
  computed: {
  	  tabbar() {
  	    return [
  	      {
  	        title: this.$i18n.t('tabbar.首页'),
  	        icon: require('@/assets/tabbar/1.png'),
  	        iconA: require('@/assets/tabbar/1-1.png'),
  	        url: '/home'
  	      },
  	      {
  	        title: this.$i18n.t('tabbar.市场'),
  	        icon: require('@/assets/tabbar/2.png'),
  	        iconA: require('@/assets/tabbar/2-2.png'),
  	        url: '/market'
  	      },
  	   
  	      {
  	        title: this.$i18n.t('tabbar.团队'),
  	        icon: require('@/assets/tabbar/4.png'),
  	        iconA: require('@/assets/tabbar/4-4.png'),
  	        url: '/team'
  	      },
  	      {
  	        title: this.$i18n.t('tabbar.我的'),
  	        icon: require('@/assets/tabbar/5.png'),
  	        iconA: require('@/assets/tabbar/5-5.png'),
  	        url: '/my'
  	      },
  			// {
  			//   title: this.$i18n.t('客服'),
  			//   icon: require('@/assets/tabbar/3.png'),
  			//   iconA: require('@/assets/tabbar/3-3.png'),
  			//   url: '/my'
  			// }
  	    ]
  	  }
  	},
  methods: {
	  code(){
	  		new QRCode("qrcode", {
	  		  width: 91, // 二维码宽度，单位像素
	  		  height: 91, // 二维码高度，单位像素
	  		  text: this.yaourl, // 生成二维码的链接
	  		});
			 if (!isModel()) {
			          const qrCanvs = document.getElementsByTagName('canvas')[0];
			          const qrcBase64 = qrCanvs.toDataURL('image/jpeg'); // 转成jpg
			          const qrcImg = document.querySelector('#qrcode > img');
			          qrcImg.crossOrigin = 'anonymous';
			          qrcImg.src = qrcBase64;
			        }
			
	  	},
		onCopy(text) {
		      let clipboard = new Clipboard(".clipboardBtnUrl");
		      clipboard.on("success", function (e) {
		        Toast(text);
		        e.clearSelection();
		      });
		    },
    // confirmEvent() {
    //   console.log('ceshi')
    // },
    getBack() {
      this.$router.back()
    },
    btnEvent() {
      console.log('dsfs')
    }
  }
}
</script>

<style lang="scss" scoped>
.page {
  padding: 0 0px 80px 0px;
  .header {
    padding: 0 10px;
    height: 56px;
    line-height: 56px;
    display: flex;
    justify-content: space-between;

    .img {
      width: 40px;
    }
    .logg {
      font-size: 40px;
      font-family: PingFang SC-中粗体, PingFang SC;
      font-weight: normal;
      color: #ffffff;
    }
  }

  .contnet {
    .bg {
      height: 900px;
      background: url('../../assets/img/jianli.png') no-repeat;
      background-size: cover;
      .tx {
        padding-top: 500px;
        padding-left: 50px;
        font-size: 40px;
        color: #ffffff;
      }
    }
    .invitation {
      display: flex;
      justify-content: space-between;
      padding-bottom: 80px;
      margin: -280px 50px 0;
      color: #ffffff;
      // border-bottom: 1px solid #888;
      .join {
        // height: 40px;
        color: #ffffff;
        border-radius: 50px;
        padding: 20px 30px;
        width: 130px;
        margin-top: 40px;
        background-color: #0d9135;
        .arrow {
          width: 40px;
          height: 10px;
          margin-left: 30px;
        }
      }
      .num {
        margin-top: 20px;
        font-size: 40px;
      }
      .qrCode {
        width: 280px;
        height: 220px;
        // background-color: #fff;
      }
    }
    .link {
      margin: 50px 50px 190px;
      color: #888;
    }
  }
  .footerBtn {
    margin: 0 50px;
    display: flex;
    text-align: center;
    justify-content: space-between;
    .affiliate {
         flex: 1;
         border-radius: 0.66667rem;
         color: #fff;
         padding: 0.4rem 0.53333rem;
         background: #07c160;
    }
    .pay {
      flex: 1;
      font-size: 30px;
      margin-left: 30px;
      border-radius: 50px;
      padding: 30px 40px;
      background-color: #0d9135;
    }
  }
}
</style>
