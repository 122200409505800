<!-- <template>
    <div class="robot2">

        <img
                @click="show = true"
                class="img"
                src="../../assets/img/kefu.png"
                alt=""
        />
			//全局客服按钮
        <van-action-sheet v-model="show" :actions="actions" @select="onSelect"
                          style="height: 50%; text-align: left;color: white;" :description="$t('kefu1')"/>
    </div>
</template> -->

<script>
import {
    apigetkf
} from "@/http/api/";

export default {
    data() {
        return {
            show: false,
            actions: [],
            token: ""
        }
    },
    created: function () {
        var token = localStorage.getItem("key");
        if (token) {
            this.token = token;
        }

        this.init();
    },
    methods: {
        onSelect(item) {
            this.show = false
            let url = item.val.indexOf('http') !== -1 ? item.val : 'http://'+item.val;
            // console.log(url)
            window.location.href = url;
            // if (item.index == 1 || item.index == 2 || item.index == 3 || item.index == 0) {
            //     this.lianxi2(item.val);
            // }
            //
            // if (item.index == 4) {
            //     this.lianxi(item.val);
            // }
        },
        showkefu() {
            this.show = true;
            var token = localStorage.getItem("key");
            this.token = token;
            if (token) {
                this.daoshi = true;
            } else {
                this.daoshi = false;
            }
            this.init();
        },
        lianxi2(tel) {
            window.location.href = tel;
            //window.location.href =  "tg:msg?text="+tel;
        },
        lianxi(tel) {
            if (window.navigator.userAgent.match(/(iPhone|iPod|iPad);?/i)) {
                window.location.href = "https://wa.me/" + tel;
                setTimeout(function () {
                    window.location.href = "https://lc2.ys202.com";
                    window.location.href = "https://lc2.ys202.com";
                }, 2000);
            } else if (window.navigator.userAgent.match(/android/i)) {
                window.location.href = "https://wa.me/" + tel;
                ;
                setTimeout(function () {
                    window.location.href = "https://lc2.ys202.com";
                }, 2000)
            }
        },
        init() {
            apigetkf({userid: this.token}).then((res) => {
                let arr = res.info
                this.actions = arr.map(row=>{
                    return {
                        index: row.id,
                        name: row.name,
                        val: row.url
                    }
                })
                // if (arr.whatsapp) {
                //     this.actions = [
                //
                //         {name: this.$t('Grupo1 de discusión de Whatsapp'), val: arr.czkf1, index: 1},
                //         {name: this.$t('Grupo2 de discusión de Whatsapp'), val: arr.czkf2, index: 2},
                //         {name: this.$t('Grupo de intercambio de Telegram'), val: arr.yykf, index: 3},
                //         {name: this.$t('Atención al cliente en línea de Whatsapp'), val: arr.whatsapp, index: 4}];
                // } else {
                //     this.actions = [
                //
                //         {name: this.$t('Cómo comprar USDT en Bitso'), val: arr.czkf1, index: 1},
                //         {name: this.$t('Cómo comprar USDT en Binance'), val: arr.czkf2, index: 2},
                //         {name: this.$t('Grupo de intercambio de Telegram'), val: arr.yykf, index: 3},
                //     ];
                // }

            });
        }
    }
}
</script>

<style lang="scss" scoped>
.robot2 {
  position: fixed;
  bottom: 400px;
  right: 60px;

  .img {
    width: 90px;
    height: 90px;
  }

  .van-action-sheet__description {
    background-color: #2a2c2e;
  }

  .van-action-sheet__item {
    text-align: left;
  }

  .van-popup {
    background-color: #2a2c2e;

  }
}
</style>
